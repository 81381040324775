import React from 'react';
import ReactDOM from 'react-dom';
import Container from '@material-ui/core/Container';
import Login from './login.js';
import SimpleButton from "../components/buttons/simpleButton.js";
import HomeButton from "../components/buttons/homeButton.js";
import NewOrder from "./newOrder.js";
import {Animated} from "react-animated-css";
import Button from '@material-ui/core/Button';
import Arrow from "../assets/images/arrowIcon.svg";
import {withRouter} from "react-router-dom";
import "animate.css/animate.min.css";
import './screens.css';
import * as Security from '../services/securityServices.js';
import ConnexionModal from "../components/modals/connexionModalAfterCommand.js";
import RegisterModal from "../components/modals/registerModal";
import Modal from '../components/modals/resetPasswordModalWithEmail';
import BgImage from "../assets/images/bg-acc.webp";
import * as Services from '../services/apiServices.js';
import * as loader from "../assets/animations/loader.json";

//option used for spinning loader
const lottieSpinnerOption = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class LoginOrRegisterAfterCommand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: 0,
      isLoading: true,
      screenWidth: 0,
      screenHeight: 0,
      email: '',
      password: '',
      isLoaderVisible: false,
      isResetPasswordModalVisible: false,
      isModalConnexionVisible: '',
      isSnackbarVisible: '',
      isModalRegisterVisible: '',
      accountLinkToken: '',
      commandId: ''
    };
  }

   async componentDidMount() {
    document.title = "WEMET";
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    this.setState({accountLinkToken: this.props.match.params.id});
    const commandId = await Services.getCommandIdFromToken(this.props.match.params.id);
    this.setState({commandId: commandId.commandId});
    this.setState({isLoading: false});
  }

  _loginFromRegister = async (credentialsObject) => {
    this.setState({isLoaderVisible: true});
    await this._handleLogin({username: credentialsObject.email, password: credentialsObject.password, accountLinkToken: this.state.accountLinkToken});
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  _handleModalStateConnexion = () => {
    const currentState = this.state.isModalConnexionVisible;
    this.setState({isModalConnexionVisible: !currentState});
  }
  _handleModalStateRegister = () => {
    const currentState = this.state.isModalRegisterVisible;
    this.setState({isModalRegisterVisible: !currentState, isModalConnexionVisible: false});
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
  }

  //called when forgot password button clicked
  _forgotPasswordButtonClicked = () => {
    this._handleResetPasswordModalState();
  }

  //display forgot password modal
  _handleResetPasswordModalState = () => {
    const currentState = this.state.isResetPasswordModalVisible;
    this.setState({isResetPasswordModalVisible: !currentState});
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  //used to check empty fields
  _checkForEmptyFields = () => {
    if (this.state.email === '' || this.state.password === '') {
      return false;
    }
    return true;
  }

  _buttonClicked = (buttonId) => {
    if (buttonId === 'connexion') {
      this._handleModalStateConnexion();
    }
    else if (buttonId === 'inscription') {
      this._handleModalStateRegister();
    }
  }

  _handleLogin = async (data = {
    username: this.state.email,
    password: this.state.password,
  }) => {
    let res = await Services.login(JSON.stringify(data));
    if (!res.success) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Identifiants invalides"});
      this.setState({isLoaderVisible: false})
      this._handleSnackbarState();
      await sleep(1500);
      this._handleSnackbarState();
    } else {
      localStorage.setItem("isUserConnected", "true");
      localStorage.setItem("token", res.token);
      localStorage.setItem("account", JSON.stringify(res.account));
      this.setState({snackbarSeverity: 'success'});
      this.setState({snackbarMessage: "Connexion réussie"});
      this._handleSnackbarState();
      await sleep(1500);
      this._handleSnackbarState();
      this.setState({isUserConnected: true})
      this.setState({isLoaderVisible: false})
      this.setState({
        isModalConnexionVisible: false,
        isModalRegisterVisible: false,
        isResetPasswordModalVisible: false
      });
      this.props.history.push('/mes_commandes');
    }
  }

  //called when login button clicked
  _loginButtonClicked = async () => {
    //check if fields are filled
    if (!this._checkForEmptyFields()) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de remplir l'ensemble des champs"});
      this._handleSnackbarState();
      return;
    }
    //check if email format is ok
    if (!Security.isEmailFormatOk(this.state.email)) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de renseigner un email valide"});
      this._handleSnackbarState();
      return;
    }
    const data = {
      username: this.state.email,
      password: this.state.password,
    };
    this.setState({isLoaderVisible: true});
    let res = await Services.login(JSON.stringify(data));
    if (!res.success) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Identifiants invalides"});
      this.setState({isLoaderVisible: false})
      this._handleSnackbarState();
    } else {
      localStorage.setItem("isUserConnected", "true");
      localStorage.setItem("token", res.token);
      localStorage.setItem("account", JSON.stringify(res.account));
      const data = {
        userId: res.account.id,
        commandToken: this.props.match.params.id
      }
      const ret = await Services.validateCommandFromExhibition(JSON.stringify(data));
      if (!res.success) {
        this.setState({snackbarSeverity: 'error'});
        this.setState({snackbarMessage: "Une erreur est survenue lors de la validation de votre commande"});
        this.setState({isLoaderVisible: false})
        this._handleSnackbarState();
        window.location.href = "/checkout_result/error";
      }
      else {
        this.props.history.push('/mes_commandes');
      }
    }


    /*const loginSuccess = await Services.login(JSON.stringify(data));
    if (loginSuccess) {
      alert('OK');
      ReactDOM.render(<Orders />, document.getElementById('root'));
    }
    else {
      alert('KO');
    }
    this.setState({isLoaderVisible: false});*/
  }


  //handle TextField change
  _handleInputChange = (stateName, value) => {
    this.setState({[stateName]: value}, function () {
      this._checkInputForContact();
    });

    if (stateName === "deliveryCountry") {
      this._getPrices();
    }
    if (stateName == "cardJob") {
      this.setState({cardJobTitle: value});
    }
  }

  _handleState = (stateIndicator) => {
    if (stateIndicator === "-") {
      if (this.state.currentState > 0) {
        const tmp = this.state.currentState - 1;
        this.setState({currentState: tmp})
      }
    } else if (stateIndicator === "+") {
      if (this.state.currentState < 3) {
        const tmp = this.state.currentState + 1;
        this.setState({currentState: tmp})
      }
    }
  }

  render() {
    return (
      this.state.isLoading ? null :
        <div style={{
          height: '100vh',
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: "20% 22%",
          backgroundSize: "cover",
          display: 'flex'
        }}>
          <div
            style={{
              width: this.state.screenWidth < 768 ? '90%' : '42%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              background: 'white',
              borderRadius: 15,
              padding:"10px 20px 60px 20px"
            }}
          >
            <div>
              <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true} style={{height: "5em"}}>
                <h1 style={{display: "flex", justifyContent: "center", fontSize: "4em", color: "#003939"}}>WeCard</h1>
              </Animated>
              <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={1000}
                        animationOutDuration={1000} isVisible={true} style={{padding: 15, paddingBottom: 0}}>
                <h2 style={{
                  textAlign: "center",
                  fontSize: '1.3em',
                  fontWeight: "normal",
                  margin: '40px 0',
                  color: "#003939"
                }}>Pour finaliser votre commande <span style={{fontWeight: 'bold', marginLeft: 5, marginRight: 5}}>{this.state.commandId}</span> vous devez vous inscrire ou vous connecter</h2>
              </Animated>
            </div>

            {/*Second button set*/}
            <div style={this.state.currentState === 0 ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: this.state.screenWidth < 768 ? 'column' : 'row',
              flexWrap: 'wrap',
            } : {display: "none"}}>
              <Animated animationIn="fadeInLeft" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Connexion" onClick={() => this._buttonClicked('connexion')}/>
              </Animated>
              <Animated animationIn="fadeInLeft" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Inscription" onClick={() => this._buttonClicked('inscription')}/>
              </Animated>
            </div>
          </div>
          <ConnexionModal
            isModalVisible={this.state.isModalConnexionVisible}
            isSnackbarVisible={this.state.isSnackbarVisible}
            isLoaderVisible={this.state.isLoaderVisible}
            handleModalState={this._handleModalStateConnexion}
            handleInputChange={this._handleInputChange}
            loginButtonClicked={this._loginButtonClicked}
            registerButtonClicked={this._registerButtonClicked}
            forgotPasswordButtonClicked={this._forgotPasswordButtonClicked}
            handleSnackbarState={this._handleSnackbarState}
            onchangePwd={(pwd) => {
              //console.log(pwd)
              this.setState({password: pwd})
            }}
            onchangeMail={(m) => {
              console.log(m)
              this.setState({email: m})
            }}
            lottieSpinnerOption={lottieSpinnerOption}
          />

          <RegisterModal
            isModalVisible={this.state.isModalRegisterVisible}
            isSnackbarVisible={this.state.isSnackbarVisible}
            isLoaderVisible={this.state.isLoaderVisible}
            handleModalState={this._handleModalStateRegister}
            handleInputChange={this._handleInputChange}
            forgotPasswordButtonClicked={this._handleResetPasswordModalState}
            handleSnackbarState={this._handleSnackbarState}
            proceedToLogin={this._loginFromRegister}
            lottieSpinnerOption={lottieSpinnerOption}
          />

          {/*Forgot password modal*/}
          <Modal
            isResetPasswordModalVisible={this.state.isResetPasswordModalVisible}
            handleResetPasswordModalState={this._handleResetPasswordModalState}
          />
        </div>
    );
  }
}

export default withRouter(LoginOrRegisterAfterCommand);
