import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    position: 'fixed',
    zIndex: 5000,
    top: 20
  },
}));

const CustomizedSnackbars = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root} style={props.customStyle}>
      <Alert onClose={() => props.handleClose()} severity={props.severity}>{props.message}</Alert>
    </div>
  );
}

export default CustomizedSnackbars;
