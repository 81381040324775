import * as UserServices from './userServices';

// const host = 'https://wemet-bo.slapp.me';
//const publicapi = 'https://admin.wemet.fr/publicapi';
//const api = 'https://admin.wemet.fr/api';

const publicapi = 'https://wemet-bo.slapp.me/publicapi';
const api = 'https://wemet-bo.slapp.me/api';

export async function login(data) {
  try {
    return fetch(publicapi + '/login', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function login : ', error);
    return false;
  }
}

export async function forgotPassword(email) {
  try {
    return fetch(publicapi + '/forgot?email=' + email, {
      method: 'POST',
      cache: 'default',
      mode: 'cors',
      body: JSON.stringify({email}),
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function forgotPassword : ', error);
    return false;
  }
}

export async function getPrices() {
  try {
    return fetch(publicapi + '/prices', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getPrices : ', error);
    return false;
  }
}

export async function getMe() {
  try {
    return fetch(api + '/user', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getMe : ', error);
    return false;
  }
}

export async function updateProfile(data) {
  try {
    return fetch(api + '/user/account', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function updateProfile : ', error);
    return false;
  }
}

export async function changePassword(data) {
  try {
    return fetch(api + '/user/account/password', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function changePassword : ', error);
    return false;
  }
}

export async function saveUserPicture(data) {
  try {
    return fetch(api + '/order/uploadFile', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function saveUserPicture : ', error);
    return false;
  }
}

export async function resetPassword(data) {
  try {
    return fetch(api + '/login', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function resetPassword : ', error);
    return false;
  }
}

export async function registerForExhibition(data) {
  try {
    return fetch(publicapi + '/registerForExhibition', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function register : ', error);
    return false;
  }
}

export async function getOrders() {
  try {
    return fetch(api + '/order/orders', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrders : ', error);
    return false;
  }
}

export async function getOrder(orderId) {
  try {
    return fetch(api + '/order/' + orderId, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrder : ', error);
    return false;
  }
}

export async function getOrderPrice(payLinkToken) {
  try {
    return fetch(publicapi + '/order/price/' + payLinkToken, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderPrice : ', error);
    return false;
  }
}

export async function getOrderBat(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/bat', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderBat : ', error);
    return false;
  }
}

export async function getOrderInvoice(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/invoice', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderInvoice : ', error);
    return false;
  }
}


export async function getOrderPay(payLinkToken) {
  try {
    return fetch(publicapi + '/order/pay/' + payLinkToken, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
      }
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderPay : ', error);
    return false;
  }
}


export async function getDuplicateOrder(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/duplicate', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getDuplicateOrder : ', error);
    return false;
  }
}


export async function getOrderCSV(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/csvfile', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'csv_order_' + orderId + '.xlsx';
        a.click();
      })
    })
    .catch(error => {
      console.log('Error with function getOrderCSV : ', error);
      return false;
    });
  /*try {
    return fetch(api + '/order/' + orderId + '/csvfile', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then((response) => response.text())
    .then((responseText) => saveStreamCSV('csv_order_' + orderId + '.xlsx', responseText))
  } catch (error) {
    console.log('Error with function getOrderCSV : ', error);
    return false;*/
  }
  catch(error) {
    console.log(error)
  };
}


export async function orderCompareCSV(orderId, csv) {
  var data = new FormData()
  data.append('csv', csv)

  try {
    return fetch(api + '/order/' + orderId + '/compareCsv', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function orderCompareCSV : ', error);
    return false;
  }
}


export async function orderDuplicateWithCSV(orderId, csv) {
  var data = new FormData()
  data.append('csv', csv)

  try {
    return fetch(api + '/order/' + orderId + '/duplicateWithCsv', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function orderDuplicateWithCSV : ', error);
    return false;
  }
}


export async function getCheckPromoCode(promocode) {
  try {
    return fetch(publicapi + '/checkPromoCode?code=' + promocode, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getCheckPromoCode : ', error);
    return false;
  }
}


export async function getMyCards() {
  try {
    return fetch(api + '/user/cards', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getMyCards : ', error);
    return false;
  }
}


export async function addCard(data) {
  try {
    return fetch(api + '/user/cards/create', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function addCard : ', error);
    return false;
  }
}

export async function deleteCard(data) {
  try {
    return fetch(api + '/user/cards/delete', {
      method: 'DELETE',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function deleteCard : ', error);
    return false;
  }
}

export async function checkout(data) {
  try {
    return fetch(api + '/order/create', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function checkout : ', error);
    return false;
  }
}

export async function getCommandIdFromToken(id) {
  const url = publicapi + "/getCommandIdFromToken/" + id;
  try {
    return fetch(url, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getCommandIdFromToken : ', error);
    return false;
  }
}

export async function validateCommandFromExhibition(data) {
  try {
    return fetch(api + '/order/validateCommandFromExhibition', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function validateCommandFromExhibition : ', error);
    return false;
  }
}

export async function checkoutForExhibition(data) {
  const requestOptions = {
    method: 'POST',
    cache: 'default',
    body: data,
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
    }
  };
  try {
    const url = publicapi + '/createForExhibition';
    const data = await fetch(url, requestOptions);
    return data.json();
  }
  catch(error) {
    console.log('Error with function checkoutForExhibition : ', error);
    return false;
  }
}

export async function getImagesFromBank() {
  try {
    return fetch(publicapi + '/getImagesFromBank', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getImagesFromBank : ', error);
    return false;
  }
}

export async function register(data) {
  try {
    return fetch(publicapi + '/register', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function register : ', error);
    return false;
  }
}

export async function getQrcodeForUpload() {
  const url = publicapi + "/getQrcodeForFileUpload/";
  try {
    return fetch(url, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getQrcodeForUpload : ', error);
    return false;
  }
}

export async function sendFileFromQrcode(data) {
  const url = publicapi + "/sendFileFromQrcode/";
  try {
    return fetch(url, {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getCommandIdFromToken : ', error);
    return false;
  }
}

export async function getNewlyUpdatedFile(data) {
  const url = publicapi + "/getNewlyUpdatedFile/";
  try {
    return fetch(url, {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getCommandIdFromToken : ', error);
    return false;
  }
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

const saveStreamCSV = (filename, text) => {

  /*response.blob().then(blob => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'mes_trajets.xlsx';
    a.click();
  });*/

  /*if(window.navigator.msSaveBlob) {
    alert('0')
      // IE 10 and later, and Edge.
      var blobObject = new Blob([text], {type: 'application/vnd.ms-excel'});
      window.navigator.msSaveBlob(blobObject, filename);
  } else {
      // Everthing else (except old IE).
      // Create a dummy anchor (with a download attribute) to click.
      var anchor = document.createElement('a');
      anchor.download = filename;
      if(window.URL.createObjectURL) {
        alert('1')
          // Everything else new.
          var blobObject = new Blob([s2ab(atob(text))], {type: 'application/octet-stream'});
          anchor.href = window.URL.createObjectURL(blobObject);
      } else {
        alert('2')
          // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
          // Load up the data into the URI for "download."
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
      }
      // Now, click it.
      if (document.createEvent) {
          var event = document.createEvent('MouseEvents');
          event.initEvent('click', true, true);
          anchor.dispatchEvent(event);
      }
      else {
          anchor.click();
      }
  }*/
}


/*import * as UserServices from './userServices';

const publicapi = 'https://admin.wemet.fr/publicapi';
const api = 'https://admin.wemet.fr/api';

export async function login(data) {
  try {
    return fetch(publicapi + '/login', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function login : ', error);
    return false;
  }
}

export async function forgotPassword(email) {
  try {
    return fetch(publicapi + '/forgot?email=' + email, {
      method: 'POST',
      cache: 'default',
      mode: 'cors',
      body: JSON.stringify({email}),
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function forgotPassword : ', error);
    return false;
  }
}

export async function getPrices() {
  try {
    return fetch(publicapi + '/prices', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getPrices : ', error);
    return false;
  }
}

export async function getCommandIdFromToken(id) {
  const url = publicapi + "/getCommandIdFromToken/" + id;
  try {
    return fetch(url, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getCommandIdFromToken : ', error);
    return false;
  }
}


export async function getMe() {
  try {
    return fetch(api + '/user', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function login : ', error);
    return false;
  }
}

export async function updateProfile(data) {
  try {
    return fetch(api + '/user/account', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function updateProfile : ', error);
    return false;
  }
}

export async function changePassword(data) {
  try {
    return fetch(api + '/user/account/password', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function changePassword : ', error);
    return false;
  }
}

export async function saveUserPicture(data) {
  try {
    return fetch(api + '/order/uploadFile', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken()
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function saveUserPicture : ', error);
    return false;
  }
}


export async function resetPassword(data) {
  try {
    return fetch(api + '/login', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function resetPassword : ', error);
    return false;
  }
}

export async function register(data) {
  try {
    return fetch(publicapi + '/register', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function register : ', error);
    return false;
  }
}

export async function getOrders() {
  try {
    return fetch(api + '/order/orders', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrders : ', error);
    return false;
  }
}

export async function getOrder(orderId) {
  try {
    return fetch(api + '/order/' + orderId, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrder : ', error);
    return false;
  }
}

export async function getOrderBat(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/bat', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderBat : ', error);
    return false;
  }
}

export async function getOrderInvoice(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/invoice', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderInvoice : ', error);
    return false;
  }
}


export async function getOrderPay(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/pay', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getOrderPay : ', error);
    return false;
  }
}


export async function getDuplicateOrder(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/duplicate', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getDuplicateOrder : ', error);
    return false;
  }
}


export async function getOrderCSV(orderId) {
  try {
    return fetch(api + '/order/' + orderId + '/csvfile', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'csv_order_' + orderId + '.xlsx';
        a.click();
      })
    })
    .catch(error => {
      console.log('Error with function getOrderCSV : ', error);
      return false;
    });
  /*try {
    return fetch(api + '/order/' + orderId + '/csvfile', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then((response) => response.text())
    .then((responseText) => saveStreamCSV('csv_order_' + orderId + '.xlsx', responseText))
  } catch (error) {
    console.log('Error with function getOrderCSV : ', error);
    return false;*/
  /*}
  catch(error) {
    console.log(error)
  };
}


export async function orderCompareCSV(orderId, csv) {
  var data = new FormData()
  data.append('csv', csv)

  try {
    return fetch(api + '/order/' + orderId + '/compareCsv', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function orderCompareCSV : ', error);
    return false;
  }
}


export async function orderDuplicateWithCSV(orderId, csv) {
  var data = new FormData()
  data.append('csv', csv)

  try {
    return fetch(api + '/order/' + orderId + '/duplicateWithCsv', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function orderDuplicateWithCSV : ', error);
    return false;
  }
}


export async function getCheckPromoCode(promocode) {
  try {
    return fetch(publicapi + '/checkPromoCode?code=' + promocode, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    }).then(res => res.json())
    .then(res => res)
  } catch (error) {
    console.log('Error with function getCheckPromoCode : ', error);
    return false;
  }
}


export async function getMyCards() {
  try {
    return fetch(api + '/user/cards', {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function getMyCards : ', error);
    return false;
  }
}


export async function addCard(data) {
  try {
    return fetch(api + '/user/cards/create', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function addCard : ', error);
    return false;
  }
}

export async function deleteCard(data) {
  try {
    return fetch(api + '/user/cards/delete', {
      method: 'DELETE',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function deleteCard : ', error);
    return false;
  }
}

export async function validateCommandFromExhibition(data) {
  try {
    return fetch(apiTest + '/order/validateCommandFromExhibition', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function validateCommandFromExhibition : ', error);
    return false;
  }
}

export async function checkout(data) {
  try {
    return fetch(api + '/order/create', {
      method: 'POST',
      cache: 'default',
      body: data,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + UserServices.getToken(),
      }
    }).then(res => res.json())
      .then(res => res)
  } catch (error) {
    console.log('Error with function checkout : ', error);
    return false;
  }
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

const saveStreamCSV = (filename, text) => {

  /*response.blob().then(blob => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'mes_trajets.xlsx';
    a.click();
  });*/

  /*if(window.navigator.msSaveBlob) {
    alert('0')
      // IE 10 and later, and Edge.
      var blobObject = new Blob([text], {type: 'application/vnd.ms-excel'});
      window.navigator.msSaveBlob(blobObject, filename);
  } else {
      // Everthing else (except old IE).
      // Create a dummy anchor (with a download attribute) to click.
      var anchor = document.createElement('a');
      anchor.download = filename;
      if(window.URL.createObjectURL) {
        alert('1')
          // Everything else new.
          var blobObject = new Blob([s2ab(atob(text))], {type: 'application/octet-stream'});
          anchor.href = window.URL.createObjectURL(blobObject);
      } else {
        alert('2')
          // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
          // Load up the data into the URI for "download."
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
      }
      // Now, click it.
      if (document.createEvent) {
          var event = document.createEvent('MouseEvents');
          event.initEvent('click', true, true);
          anchor.dispatchEvent(event);
      }
      else {
          anchor.click();
      }
  }*/
//}
