import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import '../components.css';
import Button from "@material-ui/core/Button";
import ImageUpload from 'image-upload-react';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import * as Services from '../../services/apiServices';

const SimpleCommandInput = (props) => {

  const [imageSrc, setImageSrc] = useState('')
  const [base64, setBase64] = useState('')
  const [type, setType] = useState('')
  const [errorXlsx, setErrorXlsx] = useState(null);
  const [resultZip, setResultZip] = useState(null);
  const [errorZip, setErrorZip] = useState(false);

  const downloadCSV = () => {
    var link = document.createElement('a');
    link.href = "/files/card_data-exemple.xlsx";
    link.download = "card_data-exemple.xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const CSVToArray = (strData, strDelimiter) => {
		// Check to see if the delimiter is defined. If not,
		// then default to comma.
    const arrayData = strData.split(",");
    console.log('array data')
    console.log(arrayData)
    let returnArray = [];
    for (let i = 0; i < arrayData.length; i++) {
      if (i > 15) {
        returnArray.push(arrayData[i]);
      }
    }
    return returnArray;
		// Create a regular expression to parse the CSV values.
		/*var objPattern = new RegExp(
			(
				// Delimiters.
				"(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

				// Quoted fields.
				"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

				// Standard fields.
				"([^\"\\" + strDelimiter + "\\r\\n]*))"
			),
			"gi"
			);


		// Create an array to hold our data. Give the array
		// a default empty first row.
		var arrData = [[]];

		// Create an array to hold our individual pattern
		// matching groups.
		var arrMatches = null;


		// Keep looping over the regular expression matches
		// until we can no longer find a match.
		while (arrMatches = objPattern.exec( strData )){

			// Get the delimiter that was found.
			var strMatchedDelimiter = arrMatches[ 1 ];

			// Check to see if the given delimiter has a length
			// (is not the start of string) and if it matches
			// field delimiter. If id does not, then we know
			// that this delimiter is a row delimiter.
			if (
				strMatchedDelimiter.length &&
				(strMatchedDelimiter != strDelimiter)
				){

				// Since we have reached a new row of data,
				// add an empty row to our data array.
				arrData.push( [] );

			}


			// Now that we have our delimiter out of the way,
			// let's check to see which kind of value we
			// captured (quoted or unquoted).
			if (arrMatches[ 2 ]){

				// We found a quoted value. When we capture
				// this value, unescape any double quotes.
				var strMatchedValue = arrMatches[ 2 ].replace(
					new RegExp( "\"\"", "g" ),
					"\""
					);

			} else {

				// We found a non-quoted value.
				var strMatchedValue = arrMatches[ 3 ];

			}


			// Now that we have our value string, let's add
			// it to the data array.
			arrData[ arrData.length - 1 ].push( strMatchedValue );
		}

		// Return the parsed data.
		return( arrData );*/
	}

  const proceedXLSX = () => {
    setErrorZip(false);
    setResultZip(null);
    var file = document.getElementById('xlsxInputFile').files[0];
    let cardUsers = [];

    var reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Fix \r \n , ; */
        var range = XLSX.utils.decode_range(ws["!ref"]);
        for(var R = range.s.r; R <= range.e.r; ++R) for(var C = range.s.c; C <= range.e.c; ++C) {
          var addr = XLSX.utils.encode_cell({r:R,c:C});
          if(!ws[addr] || ws[addr].t != "s") continue;
            if (ws[addr].v) {
              ws[addr].v = ws[addr].v.replace(/(\r\n|\n|\r)/gm, " ");
              ws[addr].v = ws[addr].v.replace(/(,|;)/gm, " -");
            }

            if (ws[addr].r) {
              ws[addr].r = ws[addr].r.replace(/(\r\n|\n|\r)/gm, " ");
              ws[addr].r = ws[addr].r.replace(/(,|;)/gm, " -");
            }

            if (ws[addr].w) {
              ws[addr].w = ws[addr].w.replace(/(\r\n|\n|\r)/gm, " ");
              ws[addr].w = ws[addr].w.replace(/(,|;)/gm, " -");
            }
        }

        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_csv(ws, {header:1});
        data = data.replace('/\r/g', '').replace('/\n/g', ',');
        data = data.split("\n");
        for (let i = 0; i < data.length; i++) {
          const ligne = data[i].split(",");
          if (ligne[0] && ligne[0] != "") {
            cardUsers.push({
              lastName: ligne[0],
              firstName: ligne[1],
              job: ligne[2],
              enterprise: ligne[3],
              email: ligne[4],
              website: ligne[5],
              phone: ligne[6],
              phone2: ligne[7],
              fax: ligne[8],
              address: ligne[9],
              address2: ligne[10],
              zipCode: ligne[11],
              city: ligne[12],
              country: ligne[13],
              quantity: 1,
              avatarNameZip: ''
            });
          }
        }
        cardUsers.shift();
        setErrorXlsx(null);
        props.saveCardUsers(cardUsers);
    };
    reader.readAsBinaryString(file);
    /*reader.onload = (event) => {
        const array = CSVToArray(event.target.result, ";");

        for (const element of array) {
          if (element[0] && element[0] != "" && element[1] && element[1] != "") {
            cardUsers.push({
              lastName: element[0],
              firstName: element[1],
              job: element[2],
              enterprise: element[3],
              email: element[4],
              website: element[5],
              phone: element[6],
              phone2: element[7],
              fax: element[8],
              address: element[9],
              address2: element[10],
              zipCode: element[11],
              city: element[12],
              country: element[13],
              quantity: element[14],
              avatarNameZip: element[15]
            });
          }
        }

        cardUsers.shift();
        setErrorXlsx(null);
        props.saveCardUsers(cardUsers);
    }*/

    // reader.readAsText(file);
  }

  const proceedZip = () => {
    setErrorZip(false);
    var file = document.getElementById('zipInputFile').files[0];
    var jszip = new JSZip();
    try {
      jszip.loadAsync(file)
      .then(async function(zip) {
          var files = Object.keys(zip.files).map(function (name) {
            return zip.files[name];
          });
          let associatedNameNb = 0;
          let cardUsers = props.cardUsers;
          for (const file of files) {
            for (const cardUser of cardUsers) {
              const fileNameSplit = file.name.split("/");
              const fileName = fileNameSplit[fileNameSplit.length-1];
              if (cardUser.avatarNameZip == fileName) {
                associatedNameNb++;
                const fileContent = await zip.file(file.name).async("base64");
                const fileType = file.name.split(".")[file.name.split(".").length - 1];

                await Services.saveUserPicture(JSON.stringify({avatar: fileContent, type: "image/"+fileType})).then(res => {
                  cardUser.avatar = res.fileName;
                });
              }
            }
            props.saveCardUsers(cardUsers);
            setResultZip(associatedNameNb);
          }
      })
      .catch((err) => {
        setErrorZip(true);
      });
    } catch (err) {
      setErrorZip(true);
    }
  }

  const handleImageSelect = (e) => {
    // Services.saveUserPicture(JSON.stringify({avatar: 'cc'}))
    const file = e.target.files[0];
    setType(file.type)
    setImageSrc(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    if (imageSrc === null || imageSrc === '') return;
    const blobToBase64 = function (blob) {
      const reader = new FileReader();
      reader.onload = function () {
        let dataUrl = reader.result;
        let base64 = dataUrl.split(',')[1];
        setBase64(base64)
      };
      reader.readAsDataURL(blob);
    };

    const getBase64 = async () => {
      let b = await fetch(imageSrc);
      b = await b.blob();
      const b64 = blobToBase64(b);
      return b64;
    }

    getBase64();
  }, [imageSrc])

  useEffect(() => {
    if (base64 === null || base64 === '') return;

    Services.saveUserPicture(JSON.stringify({avatar: base64, type})).then(res => {
      //console.log(res)
      props.importPicture(res.fileName);
    })
  }, [base64])

  return (
    props.isCommandMultiple ? (
      <div style={{marginBottom: '100px'}}>
        <h2 style={{width: "120%"}}>Complétez les informations de vos collaborateurs</h2>
        <p style={{marginBottom: '1.5em'}}>Téléchargez le fichier de référence, complétez les informations de vos collaborateurs et envoyez votre fichier en cliquant sur le bouton ci-dessous :</p>
        <p className='displayNone' style={{marginBottom: '2em', fontSize: 12}}>note : pour envoyer les photos du contact, veuillez renseigner les noms des images dans le fichier de référence, puis envoyez le dossier photo en format .zip</p>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div
            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: "1.4em"}}>
            <div onClick={() => downloadCSV()} className="hoverButtonLogin pointer" style={{width: '200px'}}>
              <span className="hoverButtonLoginText" >Télécharger le fichier<br/>de référence (.xlsx)</span>
            </div>
            <Button
              className="hoverButtonLogin pointer" style={{borderRadius: '35px', padding: '0', fontFamily: 'tradeGothic', width: '200px'}}
              variant="contained"
              component="label"
              disableRipple="true"
              disableFocusRipple="true"
              disableElevation="true"
            >
              Envoyer mon fichier (.xlsx)
              <input
                type="file"
                hidden
                id="xlsxInputFile"
                onChange={(event)=> {
                  proceedXLSX()
                }}
              />
            </Button>
          </div>
        </div>
        { props.cardUsers &&
          <div className='displayNone' style={{display: 'flex', flexDirection: 'column'}}>
            <div
              style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: "1.4em", alignSelf: 'flex-end'}}>
              <Button
                className="hoverButtonLogin pointer" style={{borderRadius: '35px', padding: '0', fontFamily: 'tradeGothic', width: '200px'}}
                variant="contained"
                component="label"
                disableRipple="true"
                disableFocusRipple="true"
                disableElevation="true"
              >
                Envoyer mes photos (.zip)
                <input
                  type="file"
                  hidden
                  id="zipInputFile"
                  onChange={(event)=> {
                    proceedZip()
                  }}
                />
              </Button>
            </div>
          </div>
        }
        { errorXlsx &&
          <h5 style={{width: "120%", color: 'red'}}>Erreur lors du chargement de votre fichier</h5>
        }
        { props.cardUsers &&
          <h5 style={{width: "120%", color: 'green'}}>{props.cardUsers.length} personnes importés</h5>
        }
        { errorZip &&
          <h5 style={{width: "120%", color: 'red'}}>Erreur lors du chargement de vos photos</h5>
        }
        { resultZip !== null &&
          <h5 style={{width: "120%", color: 'green'}}>{resultZip} photos associées</h5>
        }
      </div>
    ) : (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}}>
        <h2 style={{marginBottom: '2em', textAlign: "center"}}>Fiche de contact </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: "1.4em",
            width: "100%"
          }}>
          <div className='displayNone' style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
            <div style={{textAlign: 'center', margin: 'auto'}}>
              {/*<input*/}
              {/*  accept="image/*"*/}
              {/*  style={{display: 'none'}}*/}
              {/*  id="raised-button-file"*/}
              {/*  multiple*/}
              {/*  type="file"*/}
              {/*  onChange={(e) => {*/}
              {/*    props.importPicture(e.target.files[0]);*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<div style={{width: 100, height: 100, backgroundColor: 'grey', borderRadius: '50%'}}></div>*/}
              {/*<label htmlFor="raised-button-file">*/}
              {/*  <Button variant="raised" component="span" style={{border: '1px solid grey', marginTop: 10}}>*/}
              {/*    Upload*/}
              {/*  </Button>*/}
              {/*</label>*/}
              <ImageUpload
                handleImageSelect={handleImageSelect}
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: '50%',
                  backgroundColor: 'grey',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 0,
                  marginLeft: 10,
                  cursor: 'pointer'
                }}
              />
              <label htmlFor="raised-button-file">
                Photo de profil
              </label>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', width: "100%", /*marginLeft: 23*/}}>
            <TextField style={{marginBottom: '3%', width: '100%'}} value={props.cardFirstName} variant="outlined"
                       onChange={(e) => props.handleInputChangeForCardContact('cardFirstName', e.target.value)} label='Prénom*'/>
            <TextField style={{marginBottom: '3%', width: '100%'}} value={props.cardlastName} variant="outlined"
                       onChange={(e) => props.handleInputChangeForCardContact('cardlastName', e.target.value)} label='Nom*'/>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: "1.4em",
            width: "100%"
          }}>

          <TextField style={{width: '48%'}} variant="outlined" value={props.cardPhoneNumberFix}
                     onChange={(e) => props.handleInputChange('cardPhoneNumberFix', e.target.value)} label='Téléphone fixe'/>
          <TextField style={{width: '48%'}} value={props.cardPhoneNumber} variant="outlined"
                     onChange={(e) => props.handleInputChangeForCardContact('cardPhoneNumber', e.target.value)}
                     label='Téléphone mobile'/>

        </div>
        <TextField style={{width: '100%'}} value={props.cardEmail} variant="outlined"
                   onChange={(e) => props.handleInputChange('cardEmail', e.target.value)} label='Email*'/>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: "1.4em",
            width: "100%"
          }}>


        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: "1.4em",
            width: "100%"
          }}>
          <TextField style={{width: '48%'}} value={props.cardCompanyName} variant="outlined"
                     onChange={(e) => props.handleInputChange('cardCompanyName', e.target.value)} label='Société'/>
          <TextField style={{width: '48%'}} variant="outlined"
                     value={props.cardJob}
                     onChange={(e) => props.handleInputChange('cardJob', e.target.value)} label='Poste'/>
        </div>
        <TextField style={{width: '100%'}} value={props.cardAddress} variant="outlined"
                   onChange={(e) => props.handleInputChange('cardAddress', e.target.value)} label='Adresse'/>
        <TextField style={{width: '100%'}} value={props.cardAddress2} variant="outlined"
                   onChange={(e) => props.handleInputChange('cardAddress2', e.target.value)} label="Complément d'adresse"/>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: "100%"
          }}>
          <TextField style={{width: '48%'}} value={props.cardZipCode} variant="outlined"
                     onChange={(e) => props.handleInputChange('cardZipCode', e.target.value)} label='Code postal'/>
          <TextField style={{width: '48%'}} variant="outlined"
                     value={props.cardCity}
                     onChange={(e) => props.handleInputChangeForCardContact('cardCity', e.target.value)} label='Ville'/>
        </div>
        <TextField style={{width: '100%', marginBottom: "1.4em"}} value={props.cardCountry} variant="outlined"
                   onChange={(e) => props.handleInputChange('cardCountry', e.target.value)} label="Pays"/>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: "1.4em",
            width: "100%"
          }}>

          <TextField style={{width: '48%'}} variant="outlined"
                     value={props.cardWebsite}
                     onChange={(e) => props.handleInputChange('cardWebsite', e.target.value)} label='Site internet'/>
          <TextField style={{width: '48%'}} variant="outlined"
                     value={props.cardFax}
                     onChange={(e) => props.handleInputChange('cardFax', e.target.value)}
                     label='Fax'/>
        </div>
      </div>
    )
  );
}

export default SimpleCommandInput;
