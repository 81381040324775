import React from 'react';
import MultipleCommandInput from '../fileExplorer/csvContactFileExplorer.js';
import SimpleCommandInput from './simpleCommandInput.js';
import ImportPictureButton from '../fileExplorer/importUserProfilePicture.js';

import Button from '@material-ui/core/Button';

import '../components.css';

const ContactComponent = (props) => {
  return (
    <div>
      <div className="c-formulaire_field_outlined">
        <SimpleCommandInput {...props} />
      </div>
    </div>
  );
}

export default ContactComponent;
