import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import WHITE from "../../assets/images/base_blanc.png";
import BLACK from "../../assets/images/base_noire.png";
import WOOD from "../../assets/images/base_bois.png";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    marginLeft: "auto",
    marginRight: "auto"
  },
  rootMin: {
    display: 'flex',
    overflow: 'hidden',
    width: "18em"
  },
  gridList: {
    width: '100%',
    height: 450,
  },
}));

 const tileData = [
   {
     img: WHITE,
   },
   {
     img: BLACK,
   },
   {
     img: WOOD,
   },
 ];

const TextureGrid = (props) => {
  const classes = useStyles();

  return (
    props.screenWidth > 1000
    ?
    <div className={classes.root}>
      <GridList cellHeight={200} className={classes.gridList} cols={2}>
        {tileData.map((tile, index) => (
          <GridListTile key={index} cols={1}>
            <img onClick={() => props.handleCardBase(index)} id='textureGridItem' className="pointer" src={tile.img} style={props.selectedCardBaseIndex === index ? {opacity: '100%'} : {opacity: '50%'}}/>
            <p>{tile.title}</p>
          </GridListTile>
        ))}
      </GridList>
    </div>
    :
    <div className={classes.rootMin}>
      <GridList cellHeight={200} className={classes.gridList} cols={2}>
        {tileData.map((tile, index) => (
          <GridListTile key={index} cols={1}>
            <img onClick={() => props.handleCardBase(index)} id='textureGridItem' className="pointer" src={tile.img} style={props.selectedCardBaseIndex === index ? {opacity: '100%'} : {opacity: '50%'}}/>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

export default TextureGrid;
