import React from 'react';
import CardGrid from '../grids/cardGrid.js';
import '../components.css';

const CardTypeComponent = (props) => {
  return (
    <div style={{marginBottom: '1em'}}>
        <h2 className='' style={{marginBottom: '1em'}}>Choix de la base de la carte </h2>
        <CardGrid {...props}/>
    </div>
  );
}

export default CardTypeComponent;
