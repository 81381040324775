import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import '../components.css';
import {AiFillFacebook, AiOutlineArrowRight} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {AiFillLinkedin} from 'react-icons/ai';
import FixedHelpBtn from "../fixedHelpBtn/FixedHelpBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#07373C",
    width: "101%",
    marginTop: 200,
    marginLeft: -8,
    marginBottom: -8,
    paddingTop: 68,
    paddingBottom: 68,
  },
  footerDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: "hidden",
    width: "81.25vw",
    margin: "0 auto",
  },
  logoDiv: {
    display: "inline-block",
    width: "9.94vw"
  },
  logoLink: {
    display: "inline-block",
    width: "100%"
  },
  listParent: {
    margin: 0
  },
  listItem: {
    listStyle: "none",
    listStyleType: "none",
    color: 'white',
    marginBottom: 10,
    fontSize: 15
  },
  whiteText: {
    color: 'white',
    margin: 0,
    fontSize: 12,
    display: "flex",
    alignItems: 'center',
  },
  icon: {
    marginLeft: 10,
    width: 24,
    height: 24
  },
  copyright: {
    color: 'white',
    fontSize: 12
  },
  whiteLink: {
    color: 'white',
    textDecoration: 'none'
  },
  verticalSeparator: {
    margin: 10
  },
  frenchTechLink: {
    display: "inline-block",
    width: "8.6vw"
  },
  logoFrenchTech: {
    width: "100%",
    height: "auto"
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.footerDiv}>
        <div className={classes.logoDiv}>
          <a className={classes.logoLink} href="http://wemet.fr/" title="La carte de visite connectée">
            <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.3 55.5">
              <path className="st0" fill={"white"}
                    d="M28.2 53.4L22.8 21h-.1l-5.2 32.4h-8L0 5.8h7.9l5.6 32.6h.1L19 5.8h7.8l5.4 32.6h.1l5.6-32.6h7.8l-9.5 47.6h-8zm20.6 0V5.8H74V13H56.6v12.4h12.3v6.9H56.6v14h17.3v7.2H48.8zm70.6 0V5.8h25.2V13h-17.3v12.4h12.3v6.9h-12.3v14h17.3v7.2h-25.2zm-11.2 0v-35h-.1l-8.6 35H94l-8.8-35h-.1v35h-6.9V5.8h10.7l7.8 32h.1l8.1-32h10.4v47.6h-7.1zm49.4 0V13h-9.8V5.8h27.4V13h-9.8v40.4h-7.8zm85.5 2.1c-1.9 0-4.3-.7-7.1-2.9-1-.8-1.9-1.6-2.7-2.4-3.4-3.3-5.1-4.9-11.1.7-2.4 2.3-4.9 3-7.3 2.3-1.9-.6-3.6-2.1-5.1-4.2-1.9 1-4.1 1.6-6.5 1.4-9.8-.5-16.8-11.8-19.1-22-3.1-13.5 1.5-26 7.7-28.1 1.8-.6 3.4-.5 4.8.3 3.7 2.2 4.9 9.2 6.4 18 .5 3 1 6 1.6 9.1 1.6 7.4 3.6 13.5 5.9 17.6 3.7-3.1 6.7-8.8 9.9-16.3 1.7-4 3.5-5.9 5.7-6 3.6-.1 6.1 5 8.8 10.5 1.2 2.4 2.3 4.6 3.3 6l.1.1c2.2 3.1 4.5 6.2 7.7 6.2 1.4 0 2.9-.6 4.5-1.7.8-2.7 1.4-6 1.7-9.8 1.3-14.7 4.1-23.8 8.2-27.2 1.4-1.1 2.9-1.6 4.5-1.4 1.9.2 3.5 1.6 4.4 3.7 2.1 5 1 14.9-8.7 27.8-2.9 3.9-5.5 6.7-8 8.5-1.5 4.7-3.8 7.8-6.7 9.1-.8.5-1.8.7-2.9.7zm-14.7-11c2.4 0 4.4 1.6 6.6 3.7.8.8 1.6 1.6 2.5 2.3 1.9 1.5 4.8 3.1 7.4 2 1.6-.7 3-2.3 4.2-4.6-1.1.4-2.1.6-3.2.6-4.5 0-7.3-3.9-9.8-7.3l-.1-.1c-1.2-1.6-2.3-3.9-3.5-6.4-1.8-3.6-4.5-9-6.4-9-.4 0-1.7.5-3.3 4.4-3 7-6.2 13.8-10.9 17.5 1.2 1.7 2.5 2.7 3.7 3.1 1.5.5 3.1-.1 4.8-1.7 3.4-3.2 5.9-4.5 8-4.5zM193.9 2.6c-.4 0-.8.1-1.3.2-5.5 1.9-8.5 14.3-6 25 2.1 9.3 8.2 19.5 16.7 19.9 1.8.1 3.5-.3 5-1-3.4-5.9-5.4-14.1-6.3-18.5-.7-3.1-1.2-6.2-1.7-9.2-1.3-7.5-2.4-14.6-5.1-16.2-.3 0-.7-.2-1.3-.2zm70.4 5.8c-.8 0-1.5.3-2.2.9-2 1.7-5.7 7.2-7.3 25.5-.2 2.4-.5 4.7-.9 6.7 1.5-1.5 3.1-3.4 4.8-5.6 9-12 10.1-21.1 8.3-25.2-.6-1.3-1.4-2.1-2.3-2.2-.2-.1-.3-.1-.4-.1z"></path>
            </svg>
          </a>
        </div>
        <div>
          <ul className={classes.listParent}>
            <li className={classes.listItem}>
              <a className="link" href="http://wemet.fr/presentation-wemet"
                 title="Nous créons ce en quoi nous croyons">WEMET</a>
            </li>
            <li className={classes.listItem}>
              <a className="link" href="http://wemet.fr/wecard-wemet"
                 title="Une nouvelle expérience à portée de main">WeCard</a>
            </li>
            <li className={classes.listItem}>
              <a className="link" href="http://wemet.fr/contactez-wemet"
                 title="Contactez &amp; discutez">Contact</a>
            </li>
          </ul>
        </div>
        <div>
          <ul className={classes.listParent}>
            <li className={classes.listItem}>
              <a className="link" href="http://wemet.fr/compatibilite-wecard"
                 title="Compatibilités Smartphone">Compatibilités</a>
            </li>
            <li className={classes.listItem}>
              <a className="link" href="http://wemet.fr/wecard-wemet"
                 title="Une nouvelle expérience à portée de main">Créez votre
                WeCard</a>
            </li>
            <li className={classes.listItem}>
              <a className="link" href="http://wemet.fr/mag/" title="">Le
                mag</a>
            </li>
          </ul>
        </div>
        <div>
          <p className={classes.whiteText}>Rejoignez-nous sur
            <a target="_blank" href="https://www.facebook.com/wemet.card/"
               title="Découvrez toute l'actualité de WEMET sur Facebook" className="rs_link">
              <AiFillFacebook className={classes.icon} color={'white'}/>
            </a>
            <a target="_blank" href="https://www.instagram.com/wemet.card/"
               title="Découvrez toute l'actualité de WEMET sur Instagram" className="rs_link">
              <AiFillInstagram className={classes.icon} color={'white'}/>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/wemet/"
               title="Découvrez toute l'actualité de WEMET sur Linkedin " className="rs_link">
              <AiFillLinkedin className={classes.icon} color={'white'}/>
            </a>
          </p>

        </div>
      </div>
      <div className={classes.footerDiv} style={{marginTop: "7.81vw"}}>
        <div>
          <p className={classes.copyright}>©2021 WeMet, tous droits réservés
            <span className={classes.verticalSeparator}>|</span>
            <a className={classes.whiteLink}
               href="https://www.wemet.fr/mentions-legales"
               target="_blank"
               title="Mentions légales de www.wemet.fr">Mentions légales</a>
            <span className={classes.verticalSeparator}>|</span>
            <a className={classes.whiteLink} target="_blank" href="https://www.wemet.fr/conditions-generales-vente"
               title="Conditions générales de vente de www.wemet.fr">
              CGV
            </a>
            <span className={classes.verticalSeparator}>|</span>
            <a className={classes.whiteLink} target="_blank" href="https://www.wemet.fr/politique-confidentialite"
               title="Politique de confidentialité">
              Politique de confidentialité
            </a>
          </p>
        </div>
        <div style={{position: 'relative', zIndex: 204}}>
          <a target="_blank" href="https://www.lafrenchtechtoulouse.com/" className={classes.frenchTechLink}>
            <img src="https://www.wemet.fr/img/logo-FrenchTechToulouse.png" alt="logo-FrenchTechToulouse"
                 className={classes.logoFrenchTech}/>
          </a>
        </div>
      </div>
      <FixedHelpBtn/>
    </div>
  );
}

export default Footer;

Footer.propTypes = {
  props: PropTypes.object,
};
