import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

 const ImportLogoButton = (props) => {
  return (
    props.isSmartphoneVersion
    ?
    <div onClick={() => props.onClick()} className="pointer">
      <span className="c-article__link">{props.buttonName}</span>
      <p className="">{props.buttonInfos}</p>
    </div>
    :
    <div onClick={() => props.onClick()} className="pointer" style={{width: '10em'}}>
      <span className="c-article__link" >{props.buttonName}</span>
      <p className="" >{props.buttonInfos}</p>
    </div>
  );
}
export default ImportLogoButton;

ImportLogoButton.propTypes = {
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
};
