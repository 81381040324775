import React from 'react';
import PropTypes from 'prop-types';
import  { BsWifi } from 'react-icons/bs';
import '../components.css';
import LogoBlanc from '../../assets/images/logo_blanc.svg';
import LogoVert from '../../assets/images/logo_vert.svg';

const FrontCardContent = (props) => {
  return (
    <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
    {
      props.logoFile === '' ?
        (props.commandStep < 3 ? <img src={props.selectedCardBaseColor === 'black' ?  LogoBlanc : LogoVert} style={{width: 230, height: 'auto'}}/> : '')
      : (
        <img src={props.logoFile} style={{width: props.logoWidth, height: 'auto'}}/>
        )
    }
    </div>
  );
}
export default FrontCardContent;

FrontCardContent.propTypes = {

};
