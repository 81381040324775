import React from 'react';
import TextureGrid from '../grids/textureGrid.js';
import TextureFileExplorer from '../fileExplorer/textureFileExplorer.js';
import '../components.css';

const CardTextureComponent = (props) => {
  return (
    <div style={{marginBottom: '1em'}}>
      <div style={props.selectedCardBaseColor === 'black' ? {display: 'flex', justifyContent: 'center', flexDirection: 'column'} : {display: 'none'}}>
        <h2 className='' style={{marginBottom: '2em'}}>Choix de la texture (optionnel)</h2>
        <TextureGrid {...props}/>
      </div>
      <div style={props.selectedCardBaseColor === 'white' ? {display: 'flex', justifyContent: 'center', flexDirection: 'column'} : {display: 'none'}}>
        <h2 className='' style={{marginBottom: '2em'}}>Choix de la texture (optionnel)</h2>
        <TextureGrid {...props}/>
        <TextureFileExplorer {...props} />
      </div>
      <div style={props.selectedCardBaseColor === 'else' ? {display: 'flex', justifyContent: 'center', flexDirection: 'column'} : {display: 'none'}}>
        <h2 className='marginAuto' style={{marginBottom: '2em'}}>Aucune texture disponible pour cette base de carte</h2>
      </div>
    </div>
  );
}

export default CardTextureComponent;
