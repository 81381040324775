import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    flexGrow: 1,
  },
});

const theme = createMuiTheme({
  overrides: {
    // Style sheet name
    MuiMobileStepper: {
      // Name of the rule
      dotActive: {
        backgroundColor: "#003939"
      },
    },
  },
});

const DotsMobileStepper = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);

  return (
    <ThemeProvider theme={theme}>
      <MobileStepper
        variant="dots"
        steps={9}
        activeStep={props.commandStep}
        className={classes.root}
        nextButton={
          <Button size="small" onClick={() => props.updateCommandStep('next')} disabled={props.commandStep === 9}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={

          <Button size="small" onClick={() => props.updateCommandStep('prev')} disabled={props.commandStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>

        }
      />
    </ThemeProvider>

  );
}

export default DotsMobileStepper;
