import React from 'react';
import TextField from '@material-ui/core/TextField';
import CheckboxRow from '../rows/checkboxRow.js';
import Button from '@material-ui/core/Button';

const SmallProfileGrid = (props) => {
  const textFieldWidth = props.screenWidth * 0.80;
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <h1 style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 100}}>Profil</h1>
      {props.company !== '' ? <TextField style={{margin: 20, width: textFieldWidth}} label='Société' defaultValue={props.company} /> : null}
      {props.company !== '' ? <TextField style={{margin: 20, width: textFieldWidth}} label='Code Société' value={props.companyCode}/> : null}
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label='Nom' defaultValue={props.lastName} onChange={(e) => props.handleInputChange('lastName', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label='Prenom' defaultValue={props.firstName} onChange={(e) => props.handleInputChange('firstName', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label='Email' defaultValue={props.email} onChange={(e) => props.handleInputChange('email', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label='Téléphone' defaultValue={props.phoneNumber} onChange={(e) => props.handleInputChange('phoneNumber', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label='Adresse' defaultValue={props.address} onChange={(e) => props.handleInputChange('address', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label="Complément d'adresse" defaultValue={props.additionalAddress} onChange={(e) => props.handleInputChange('additionalAddress', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label="Code postal" defaultValue={props.postalCode} onChange={(e) => props.handleInputChange('postalCode', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label='Ville' defaultValue={props.city} onChange={(e) => props.handleInputChange('city', e)}/>
        </span>
        <div className="hoverButtonModal pointer" style={{width: "100%"}}>
          <span className="hoverButtonModalText">Changer le mot de passe</span>
        </div>
        <h2 id='subtitle'>Coordonnées bancaire</h2>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: '20em'}} label='Numéro de carte' defaultValue={props.cardNumber} onChange={(e) => props.handleInputChange('cardNumber', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label="Expire le" defaultValue={props.cardExpiration} onChange={(e) => props.handleInputChange('cardExpiration', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth}} label="Cryptogramme de sécurité" type='number' defaultValue={props.cryptogram} onChange={(e) => props.handleInputChange('cryptogram', e)}/>
        </span>
    </div>
  );
}

export default SmallProfileGrid;
