import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import "../components.css";

 const SimpleButton = (props) => {
  return (
    <div onClick={() => props.onClick()} className="hoverButton pointer" style={{minWidth: "16em", maxWidth: "20em", margin: "0.3em"}}>
      <span className="hoverButtonText">{props.buttonName}</span>
    </div>
  );
}
export default SimpleButton;

SimpleButton.propTypes = {
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
};
