import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Nordica from '../../assets/images/nordica.png';
import NugoSans from '../../assets/images/nugoSans.png';
import Epittazio from '../../assets/images/epittazio.png';
import FragmentCore from '../../assets/images/fragmentCore.png';
import ModernSans from '../../assets/images/modernSans.png';
import Pistara from '../../assets/images/pistara.png';
import Selya from '../../assets/images/selya.png';

import '../components.css';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%'
  },
  gridList: {
    width: '100%',
    height: 450,
  },
}));

 const tileData = [
   {
     img: Nordica,
   },
   {
     img: NugoSans,
   },
   {
     img: Epittazio,
   },
   {
     img: FragmentCore,
   },
   {
     img: ModernSans,
   },
   {
     img: Pistara,
   },
   {
     img: Selya,
   },
 ];

const TypoGrid = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={160} className={classes.gridList} cols={3}>
        {tileData.map((tile, index) => (
          <GridListTile key={index} cols={1}>
            <img
              className='pointer typoImg'
              onClick={() => props.fontClicked(index)}
              src={tile.img}
              style={props.selectedFontIndex === index
                ? {opacity: '100%', width: '90%', height: 'auto', border: '1px solid black', padding: '10px'}
                : {opacity: '50%', width: '90%', height: 'auto', padding: '10px'}}/>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

export default TypoGrid;
