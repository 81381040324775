import React from 'react';
import '../components.css';

const Validation = (props) => {
  return (
    <div className="validationContainer">
      <h2>Récapitulatif de votre carte</h2>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img className="marginAuto" src={props.cardFrontFace.toDataURL()} alt='cardFrontFace'
             style={{width: "49%", height: "auto", margin: 10}}/>
        <img className="marginAuto" src={props.cardBackFace.toDataURL()} alt='cardFrontFace'
             style={{width: "49%", height: "auto", margin: 10}}/>
      </div>
    </div>
  );
}

export default Validation;
