import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineArrowRight } from 'react-icons/ai';

 const NewOrderSingleBottomButtons = (props) => {
  return (
    props.firstButtonActivated
    ?
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <div className="hoverButton pointer" style={{width: 150}} onClick={() => props.updateCommandStep('next')}>
        <p className="hoverButtonLoginText">Suivant</p>
      </div>
    </div>
    :
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <div className="hoverButtonDisabled" style={{width: 150}} >
        <p className="hoverButtonLoginTextDisabled">Suivant</p>
      </div>
    </div>
  );
}
export default NewOrderSingleBottomButtons;

NewOrderSingleBottomButtons.propTypes = {

};
