import React from 'react';
import ColorGrid from '../grids/colorGrid.js';
import '../components.css';

const CardColorComponent = (props) => {
  return (
    <div style={{marginBottom: '1em'}}>
      <div className='cardGridWrapper'>
        <h2 className='' style={{marginBottom: '2em'}}>Choix de la couleur</h2>
        <ColorGrid {...props}/>
      </div>
    </div>
  );
}

export default CardColorComponent;
