import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import * as UserService from '../../services/userServices';

const getDate = () => {
  const date = new Date();
  return date.toLocaleDateString();
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },

  },
});


function Row(props) {
  const { row, displayBat, getInvoice, payOrder, duplicateOrder, updateOrder } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.referenceCode}
        </TableCell>
        <TableCell align="right">{moment(row.date).format('DD/MM/Y')}</TableCell>
        <TableCell align="right">{row.status?.status}</TableCell>
        <TableCell align="right" style={{fontWeight: "bold"}}>{row.totalTtc} €</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/*<Typography variant="h6" gutterBottom component="div">*/}
              {/*  <span style={{color: "#003732", textDecoration: "underline"}}>Détails de la commande</span>*/}
              {/*</Typography>*/}
              <Table size="small" aria-label="purchases">
                <TableHead className="test">
                  <TableRow>
                    <TableCell style={{fontWeight: "bold"}}>Date</TableCell>
                    <TableCell style={{fontWeight: "bold"}}>État de la commande</TableCell>
                    <TableCell style={{fontWeight: "bold"}}>Description</TableCell>
                    {/*<TableCell style={{fontWeight: "bold"}} align="right">Nom</TableCell>*/}
                    <TableCell style={{fontWeight: "bold"}} align="right">Adresse </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {moment(row.date).format('DD/MM/Y')}
                      </TableCell>
                      <TableCell>
                        {row.status?.status}
                      </TableCell>
                      <TableCell>
                        {row.quantity} x Carte {row.card?.cardType?.type} en mode {row.card?.orientation?.orientation}
                      </TableCell>
                      {/*<TableCell align="right">*/}
                        {/*{UserService.getAccount().firstName}*/}
                      {/*</TableCell>*/}
                      <TableCell align="right">
                        {row.address1Delivery} {row.zipCodeDelivery} {row.cityDelivery}
                      </TableCell>
                    </TableRow>
                  <div style={{display: "flex", flexWrap: "wrap", width: "100%", height: "auto", marginTop: 10}}>
                    { row.quantity > 1 || row.paid &&
                    <div onClick={() =>  getInvoice(row.id)} className="hoverSmallButton pointer">
                      <span className="hoverSmallButtonText">Télécharger la facture</span>
                    </div>
                    }
                    <div onClick={() =>  displayBat(row.id)} className="hoverSmallButton pointer">
                      <span className="hoverSmallButtonText">Afficher le BAT</span>
                    </div>
                    <div onClick={() =>  updateOrder(row.id)} className="hoverSmallButton pointer">
                      <span className="hoverSmallButtonText">Mettre à jour</span>
                    </div>
                    <div onClick={() =>  duplicateOrder(row.id)} className="hoverSmallButton pointer">
                      <span className="hoverSmallButtonText">Commander à nouveau</span>
                    </div>
                    { row.quantity == 1 && !row.paid && row.payLinkToken &&
                    <div onClick={() =>  payOrder(row.payLinkToken)} className="hoverSmallButton pointer">
                      <span className="hoverSmallButtonText">Payer par CB</span>
                    </div>
                    }
                  </div>
                  <div style={{marginTop: '15px', width: '100%', textAlign: 'center'}} id={"bat"+row.id}></div>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {

  const displayBat = async (orderId) => {
    const data = await props.getOrderBat(orderId);
    const el = document.getElementById("bat"+orderId);
  
    if (data.success) {
      el.innerHTML = "<img style='width: 200px' src='data:image/png;base64," + data.batFront + "' alt='BAT Front' /><img style='width: 200px' src='data:image/png;base64," + data.batBack + "' alt='BAT Back' />";
    } else {
      el.innerHTML = data.message;
    }
  }

  const getInvoice = async (orderId) => {
    const data = await props.getOrderInvoice(orderId);

    if (data.success) {
      var link = document.createElement('a');
      link.href = "https://admin.wemet.fr/publicapi/invoice/"+data.invoiceToken;
      link.download = "Facture_"+orderId+".pdf";
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const payOrder = async (orderId) => {
    return props.getOrderPay(orderId);
  }

  const duplicateOrder = async (orderId) => {
    await props.getDuplicateOrder(orderId);
  }

  const updateOrder = async (orderId) => {
    return props.updateOrder(orderId);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow style={{backgroundColor: "rgb(0, 57, 57)", color: 'white'}}>
            <TableCell />
            <TableCell style={{fontWeight: "bold", color: 'white'}}>Référence</TableCell>
            <TableCell style={{fontWeight: "bold", color: 'white'}} align="right">Date</TableCell>
            <TableCell style={{fontWeight: "bold", color: 'white'}} align="right">Status</TableCell>
            <TableCell style={{fontWeight: "bold", color: 'white'}} align="right">Montant</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dataLoading &&
          <TableRow>
            <TableCell>
              <p style={{textAlign: 'center'}}>Chargement...</p>
            </TableCell>
          </TableRow>
          }
          {!props.dataLoading && props.data.length === 0 &&
          <TableRow>
            <TableCell>
              <p style={{textAlign: 'center'}}>Aucune donnée.</p>
            </TableCell>
          </TableRow>
          }
          {props.data.map((row, index) => (
            <Row key={index} row={row} displayBat={displayBat} getInvoice={getInvoice} duplicateOrder={duplicateOrder} updateOrder={updateOrder} payOrder={payOrder} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
