import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
  },
  rootMin: {
    width: 300,
    marginTop: 10,
    marginBottom: 10,
  },
});

const CardLogoSizeSliderForBack = (props) => {
  const classes = useStyles();

  return (
    <div className={props.displaySmartphoneVersion ? classes.rootMin : classes.root}>
      <Typography id="discrete-slider-small-steps" gutterBottom>
        Taille du logo
      </Typography>
      <Slider
        style={{color: '#003732'}}
        onChange={(_, value) => props.updateLogoSize(value, props.type)}
        defaultValue={props.sliderDefaultValue}
        step={30}
        marks
        min={10}
        max={1000}
      />
    </div>
  );
}

export default CardLogoSizeSliderForBack;
