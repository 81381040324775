import React, {useState} from 'react';
import AppBar from '../components/appBar/homeAppBar.js';
import {withRouter, useHistory} from "react-router-dom";
import './screens.css';
import Footer from "../components/footer/footer";

const CheckoutResult = ({valid, location}) => {
  //const [result, setResult] = useState(location.state.valid);

  const history = useHistory();

  return (
    <div>
      <div className="marginAuto" style={{marginTop: 150, width: '100%', textAlign: 'center'}}>
        { valid ? (
          <>
            <h1 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Commande confirmée !</h1>
            <h3 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Merci d'avoir commandé chez WEMET. Vous recevrez prochainement un mail récapitulatif.<br/>Vous pouvez dès à présent retrouver votre commande dans votre espace client.</h3>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div className="hoverButton pointer" style={{width: 200}} onClick={() => history.push('/mes_commandes')}>
                <p className="hoverButtonLoginText">Voir mes commandes</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Commande annulée...</h1>
            <h3 style={{color: "#003939", marginBottom: 50, textAlign: 'center'} }>Une erreur est survenue, votre commande n'as pas pu aboutir.</h3>
          </>
        )}
      </div>
      <AppBar/>
      <Footer/>
    </div>
  )
}

export default withRouter(CheckoutResult);
