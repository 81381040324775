import React from 'react';
import {makeStyles, createMuiTheme} from '@material-ui/core/styles';
import {Animated} from "react-animated-css";
import {useHistory} from "react-router-dom";
import './homeAppBar.css';

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isVisible, setIsVisible] = React.useState(false);
  const [btnClassnames, setBtnClassnames] = React.useState('btn');
  const [navClassnames, setNavClassnames] = React.useState('');
  const [logoOpacity, setLogoOpacity] = React.useState(1);
  const [menuClassNames, setMenuClassNames] = React.useState('p-menu');

  const handleLogout = () => {
    localStorage.setItem("isUserConnected", "false");
    localStorage.setItem("user", null);
    localStorage.setItem("account", null);
    localStorage.setItem("token", null);
    window.location = "http://wemet.fr";
  }

  const disableScroll = () => {
    // Get the current page scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  const enableScroll = () => {
    window.onscroll = function () {
    };
  }

  const changeOpen = () => {
    if (btnClassnames.includes('ouvert')) {
      setBtnClassnames(btnClassnames.replace('ouvert', ''));
      setLogoOpacity(1);
      setNavClassnames('');
      setMenuClassNames('p-menu');
      setIsVisible(false);
      enableScroll();
    } else {
      let tmp = btnClassnames
      setBtnClassnames(tmp += " ouvert");
      setLogoOpacity(0);
      setNavClassnames('expanded');
      setMenuClassNames('p-menu ouvert');
      setIsVisible(true);
      disableScroll();
    }
  }

  return (
    <div className='navContainer'>
      <div className={classes.container}>
        <nav className={navClassnames} style={{
          width: "81.25vw",
        }}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <a href="https://www.wemet.fr/" title="La carte de visite connectée"
               style={{opacity: logoOpacity, zIndex: 1000}}>
              <img className={classes.logo} src="https://www.wemet.fr/img/logo-wemet.svg" alt="Logo WEMET"/>
            </a>
            {
              props.isUserConnected ? (
                <button className={btnClassnames} onClick={changeOpen}>
                  <span className='barre barre1'></span>
                  <span className='barre barre2'></span>
                </button>
              )
                : ''
            }
          </div>
          <div className={menuClassNames}>
            <div className={classes.container}>
              <div className="row">
                <div className="col-xs-12">
                  <div className="p-menu_content flex align-center justify-between align-start-md column-md">
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "auto",
                      flexWrap: 'wrap',
                      minHeight: 150,
                      width: "81.25vw",
                      backgroundColor: 'white',
                      paddingLeft: "8.75vw"
                    }}>
                      <div onClick={() => {
                        enableScroll();
                        history.push('/mes_commandes')
                      }} className="menuItem pointer"
                           style={{width: "25%", height: '100%', minWidth: 200, minHeight: 50}}>
                        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" animationInDelay={200}
                                  animationInDuration={1000} animationOutDuration={1000} isVisible={isVisible}>
                          <span>Mes commandes</span>
                        </Animated>
                      </div>
                      <div onClick={() => {
                        enableScroll();
                        history.push('/mon_profil')
                      }} className="menuItem pointer"
                           style={{width: "25%", height: '100%', minWidth: 200, minHeight: 50}}>
                        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" animationInDelay={450}
                                  animationInDuration={1000} animationOutDuration={1000} isVisible={isVisible}>
                          <span>Mon profil</span>
                        </Animated>
                      </div>
                      <div onClick={() => {
                        enableScroll();
                        history.push('/accueil')
                      }} className="menuItem pointer"
                           style={{width: "25%", height: '100%', minWidth: 200, minHeight: 50}}>
                        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" animationInDelay={700}
                                  animationInDuration={1000} animationOutDuration={1000} isVisible={isVisible}>
                          <span>Nouvelle commande</span>
                        </Animated>
                      </div>
                      <div onClick={() => handleLogout()} className="menuItem pointer"
                           style={{width: "25%", height: '100%', minWidth: 200, minHeight: 50}}>
                        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" animationInDelay={950}
                                  animationInDuration={1000} animationOutDuration={1000} isVisible={isVisible}>
                          <span>Déconnexion</span>
                        </Animated>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </nav>
      </div>

    </div>
  );
}


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  container: {
    // width: '100vw',
    // width: '100%',
    // backgroundColor: 'white',
    // margin: '0 auto',
    // padding: "26px 8.75vw",

  },
  nav: {},
  logo: {
    width: "11rem",
    display: "inline-block",
    pointerEvents: "normal",
    transition: "all .3s ease",
  },
  img: {
    width: "100%",
    height: "auto",
  }
  ,
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
  },
});

