import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from 'react-awesome-modal';
import TextField from '@material-ui/core/TextField';

 const HelpModal = (props) => {
  return (
    <Modal
      visible={props.isModalVisible}
      width="500px"
      effect="fadeInUp"
      style={{height: 'fit-content'}}
      onClickAway={() => props.handleModalState()}
    >
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', padding: 20}}>
        <h1>Besoin d'aide ? </h1>
        <p>Vous avez besoin d'aide ou de renseignements complémentaires pour finir la création de votre carte ? </p>
        <p>N'hesitez pas à nous contacter et nous vous répondrons dans les meilleurs délais</p>
        <div onClick={() => window.location = "https://www.wemet.fr/contactez-wemet"} className="hoverButtonLogin pointer" style={{width: "100%"}}>
          <span className="hoverButtonLoginText">Contactez-nous</span>
        </div>
      </div>
    </Modal>
  );
}
export default HelpModal;

HelpModal.propTypes = {

};
