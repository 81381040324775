import React from 'react';
import ReactDOM from 'react-dom';
import Login from './login.js';
import * as Services from '../services/apiServices.js';
import Container from '@material-ui/core/Container';
import AppBar from '../components/appBar/homeAppBar.js';
import List from '../components/lists/ordersList.js';
import OrderUpdate from '../components/modals/orderUpdateModal.js';
import OrdersTable from '../components/tables/ordersTable.js';
import {withRouter} from "react-router-dom";
import './screens.css';
import Footer from "../components/footer/footer";
import {loadStripe} from "@stripe/stripe-js";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: '',
      isBillDetailsModalVisible: false,
      isOrderUpdateModalVisible: false,
      billDetailsData: {},
      isUserConnected: false,
      data: [],
      dataLoading: true,
      selectedOrder: -1
    };
  }

  componentDidMount() {
    this._getData();
    const isUserConnected = localStorage.getItem("isUserConnected");
    if (isUserConnected === "true") {
      this.setState({isUserConnected: true});
      window.addEventListener('resize', this._updateDimension);
      this.setState({screenWidth: window.innerWidth.toString()});
    } else {
      this.props.history.push('/connexion');
    }
  }

  //called when menu is clicked
  _menuClicked = () => {
    console.log('MENU CLICKED');
  }

  //called each time screen is resize
  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth.toString()});
  }

  //display / hide bill details modal
  _handleBillDetailsModalState = (index) => {
    const currentState = this.state.isBillDetailsModalVisible;
    this.setState({isBillDetailsModalVisible: !currentState});
  }

  //display / hide bill details modal
  _handleOrderUpdateModalState = () => {
    const currentState = this.state.isOrderUpdateModalVisible;
    this.setState({isOrderUpdateModalVisible: !currentState});
  }

  _getDate = () => {
    const date = new Date();
    return date.toLocaleDateString();
  }

  _getData = async () => {
    this.setState({dataLoading: true});
    let data = await Services.getOrders();
    this.setState({data});
    this.setState({dataLoading: false});
  }

  _getOrderBat = async (orderId) => {
    return await Services.getOrderBat(orderId);
  }

  _getOrderInvoice = async (orderId) => {
    return await Services.getOrderInvoice(orderId);
  }

  _getOrderPay = async (orderId) => {
    const paiement = await Services.getOrderPay(orderId);
    if (paiement.success && paiement.sessionCheckout) {
      const stripe = await loadStripe(paiement.stripe_pk);
      return stripe.redirectToCheckout({sessionId: paiement.sessionCheckout});
    }
  }

  _getDuplicateOrder = async (orderId) => {
    if (window.confirm("Voulez-vous vraiment dupliquer cette commande ?")) {
      const duplicateOrder = await Services.getDuplicateOrder(orderId);

      if (duplicateOrder.success && duplicateOrder.sessionCheckout) {
        const stripe = await loadStripe(duplicateOrder.stripe_pk);
        return stripe.redirectToCheckout({sessionId: duplicateOrder.sessionCheckout});
      }
      return window.location.reload();
    }
  }

  _updateOrder = async (orderId) => {
    this.setState({selectedOrder: orderId});
    this._handleOrderUpdateModalState();
  }

  _getOrderCSV = async (orderId) => {
    return await Services.getOrderCSV(orderId);
  }

  render() {
    return (
      <div>
        <div
          className='container'
          style={{flexDirection: 'column'}}
        >
          <AppBar {...this.state} menuClicked={this._menuClicked}/>
          {/*Screen title*/}
          <h1 style={{marginTop: 150, marginBottom: 100, color: "#003939"}}>Mes commandes </h1>
          {/*Scrolling div for orders list*/}
          <div style={{width: "100%"}}>
            {/*Orders list*/}
            <OrdersTable data={this.state.data} dataLoading={this.state.dataLoading} getOrderBat={this._getOrderBat}
                         getOrderInvoice={this._getOrderInvoice} getDuplicateOrder={this._getDuplicateOrder}
                         updateOrder={this._updateOrder} getOrderPay={this._getOrderPay}/>
          </div>
        </div>
        <Footer/>
        <OrderUpdate {...this.state} handleOrderUpdateModalState={this._handleOrderUpdateModalState}
                     getOrderCSV={this._getOrderCSV}></OrderUpdate>
      </div>

    );
  }
}

export default withRouter(Orders);
