import React from 'react';
import PropTypes from 'prop-types';
import '../components.css';
import {IoChatboxEllipsesOutline} from "react-icons/io5";

const FixedHelpBtn = (props) => {
  return (
    <a target="_blank" href="https://www.wemet.fr/contactez-wemet" style={{
      width: 80,
      height: 80,
      backgroundColor: "#07373C",
      borderRadius: 40,
      position: "fixed",
      right: 50,
      bottom: 50,
      zIndex: 200
    }}>
      <IoChatboxEllipsesOutline style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: 30,
        transform: "translate(-50%, -50%)",
        color: "white"
      }}/>
    </a>
  );
}

export default FixedHelpBtn;

FixedHelpBtn.propTypes = {
  props: PropTypes.object,
};
