import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from 'react-awesome-modal';
import CloseModalButton from '../buttons/closeModalButton.js';
import EditPasswordModalButton from '../buttons/editPasswordModalButton';
import TextField from '@material-ui/core/TextField';
import * as Services from '../../services/apiServices';

 const ResetPasswordModalWithEmail = (props) => {
  const [email, setEmail] = useState(null);
  const [forgotResponse, setForgotResponse] = useState(null);

  const sendForgot = async () => {
    if (email) {
      const result = await Services.forgotPassword(email);
      setForgotResponse(result.message);
    }
  }

  return (
    <Modal
        visible={props.isResetPasswordModalVisible}
        width="42%"
        style={{height: 'fit-content'}}
        effect="fadeInUp"
        onClickAway={() => props.handleResetPasswordModalState()}
    >
      <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}} className="c-formulaire_field_outlined">
        <h1 style={{textAlign: 'center'}}>Récupération de mot de passe</h1>
        <p style={{width: 'auto'}}>Veuillez entrer l'adresse email de votre compte Wemet</p>
        { forgotResponse &&
          <p style={{display: 'flex', marginLeft: 20, marginRight: 20}}><h5 style={{width: "120%", color: 'green'}}>{forgotResponse}</h5></p>
        }
        <TextField style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}} label='Email*' variant="outlined" onChange={(e) => setEmail(e.target.value)} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <div onClick={sendForgot} className="hoverButtonLogin pointer" style={{width: 150, margin: 20}}>
            <span className="hoverButtonLoginText">Envoyer</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default ResetPasswordModalWithEmail;

ResetPasswordModalWithEmail.propTypes = {
  isResetPasswordModalVisible: PropTypes.bool,
  handleResetPasswordModalState: PropTypes.func,
};
