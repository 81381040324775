import React from 'react';
import ReactDOM from 'react-dom';
import Profile from './screens/profile.js';
import Orders from "./screens/orders.js";
import Home from './screens/home.js';
import NewOrder from "./screens/newOrder.js";
import Register from "./screens/register.js";
import Login from "./screens/login.js";
import LoginFromWeb from "./screens/LoginFromWeb";
import reportWebVitals from './reportWebVitals';
import LoginOrRegisterAfterCommand from './screens/loginOrRegisterAfterCommand.js';
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import CheckoutResult from "./screens/checkoutResult";
import PayOrder from "./screens/payOrder";
import SuccessResetPassword from './screens/successResetPassword.js';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path="/inscription">
        <Register />
      </Route>
      <Route exact path="/connexion">
        <Login />
      </Route>
      <Route exact path="/mon_profil">
        <Profile />
      </Route>
      <Route exact path="/mes_commandes">
        <Orders />
      </Route>
      <Route exact path="/nouvelle_commande">
        <NewOrder />
      </Route>
      <Route exact path="/accueil">
        <Home />
      </Route>
      <Route exact path="/">
        <Redirect to="/accueil" />
      </Route>
      <Route exact path="/checkout_result/error">
        <CheckoutResult valid={false} />
      </Route>
      <Route exact path="/checkout_result/success">
        <CheckoutResult valid={true} />
      </Route>
      <Route exact path="/pay_order/:payLinkToken">
        <PayOrder />
      </Route>
      <Route exact path="/mot_de_passe_ok">
        <SuccessResetPassword  />
      </Route>
      <Route exact path="/inscriptionConnexion/:id">
        <LoginOrRegisterAfterCommand />
      </Route>
      <Route exact path="/loginfromweb" component={LoginFromWeb} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
