import React from 'react';
import ReactDOM from 'react-dom';
import Login from './login.js';
import * as Services from '../services/apiServices.js';
import Promisify from 'util';
import Container from '@material-ui/core/Container';
import AppBar from '../components/appBar/homeAppBar.js';
import LeftGrid from '../components/grids/newOrderLeftGrid.js';
import LeftComponentsHanlder from '../components/blocks/leftCardComponentHandler.js';
import LeftComponentsHanlderForSmartphone from '../components/blocks/leftCardComponentHandlerForSmartphone.js';
import RightComponentsHanlder from '../components/blocks/rightCardComponentHandler.js';
import RightComponentsHanlderForSmartphone from '../components/blocks/rightCardComponentHandlerForSmartphone.js';
import BackCardContent from '../components/cards/backCardContent.js';
import {BsWifi} from 'react-icons/bs';
import Stepper from '../components/steppers/cardCreationStepper.js';
import SmartphoneStepper from '../components/steppers/cardCreationSmartphoneStepper.js';
import WecardRow from '../components/rows/wecardRow.js';
import {Animated} from "react-animated-css";
import Modal from "../components/modals/needSomeHelpModal.js";
import ModalPassword from '../components/modals/resetPasswordModalWithEmail';

import ConnexionModal from "../components/modals/connexionModal.js";
import {withRouter} from "react-router-dom";
import html2canvas from 'html2canvas';
import './screens.css';
import Footer from "../components/footer/footer";
import ImageUpload from 'image-upload-react'
import * as Security from "../services/securityServices";
import * as loader from "../assets/animations/loader.json";
import SnackBar from "../components/snackbars/snackbar";
import {loadStripe} from "@stripe/stripe-js";
import Lottie from "react-lottie";
import RegisterModal from "../components/modals/registerModal";

//implementation of sleep function
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

//option used for spinning loader
const lottieSpinnerOption = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const cardBase = [
  '',
  'Base noire : monochrome blanc',
  'Base noire : monochrome argent',
  'Base noire : monochrome doré',
  'Base blanche',
  'Base dorée ',
  'Base argenté',
  'Base bois : gravure'
];

const fontFamily = [
  {
    fontName: 'Nordica',
    fontSize: 30
  },
  {
    fontName: 'NugoSans',
    fontSize: 30
  },
  {
    fontName: 'Epittazio',
    fontSize: 30
  },
  {
    fontName: 'FragmentCore',
    fontSize: 30
  },
  {
    fontName: 'ModernSans',
    fontSize: 30
  },
  {
    fontName: 'Pistara',
    fontSize: 30
  },
  {
    fontName: 'Selya',
    fontSize: 40
  },
  ,
];

class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this._initState();
    this._cardRef = React.createRef();
    this._saveCardFace = this._saveCardFace.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    this._fontClicked(0);

    const isUserConnected = localStorage.getItem("isUserConnected");
    if (isUserConnected === "true") {
      this.setState({isUserConnected: true});

      this._getMe().then((userData) => {
        for (const data in userData) {
          switch (data) {
            case 'firstName':
              //this._handleInputChangeForCardContact('cardFirstName', userData[data]);
              this._handleInputChange('deliveryFirstName', userData[data]);
              this._handleInputChange('billingFirstName', userData[data]);
              break;
            case 'lastName':
              //this._handleInputChangeForCardContact('cardlastName', userData[data]);
              this._handleInputChange('deliveryLastName', userData[data]);
              this._handleInputChange('billingLastName', userData[data]);
              break;
            case 'phoneNumber':
              //this._handleInputChangeForCardContact('cardPhoneNumber', userData[data]);
              break;
            case 'email':
              //this._handleInputChange('cardEmail', userData[data]);
              break;
            case 'address':
              /*this._handleInputChange('cardAddress', userData[data]);
              if (userData['postalCode']) {
                this._handleInputChange('cardZipCode', userData['postalCode']);
              }
              if (userData['city']) {
                this._handleInputChange('cardCity', userData['city']);
              }
              */
              this._handleInputChange('billingAddress1', userData[data]);
              break;
            case 'company':
              if (userData[data] && userData[data].name) {
                //this._handleInputChange('cardCompanyName', userData[data].name);
                this._handleInputChange('deliveryCompanyName', userData[data].name);
                this._handleInputChange('billingCompanyName', userData[data].name);
              }
              break;
            case 'additionalAddress':
              this._handleInputChange('billingAddress2', userData[data]);
              break;
            case 'city':
              this._handleInputChange('billingCity', userData[data]);
              break;
            case 'postalCode':
              this._handleInputChange('billingZipCode', userData[data]);
              break;
            case 'promoCode':
              if (userData[data] && userData[data].code) {

                this._handleInputChange('orderPromoCode', userData[data].code);
                this._getCheckPromoCode(userData[data].code);
              }
              break;
          }
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimension);
  }

  componentDidUpdate() {
  }

  _initState = async () => {
    this.state = {
      screenWidth: '',
      screenHeight: '',
      isActivated: false,
      cardFaceDisplayed: 'front',
      backgroundColor: 'black',
      isCardHorizontal: true,
      isCardVertical: false,
      speed: 3,
      commandStep: 0,
      lastStep: 0,
      userType: '',
      selectedCardBase: 'Base noire : monochrome argent',
      selectedCardBaseIndex: 1,
      selectedCardBaseColor: 'black',
      selectedTextureIndex: -1,
      selectedCardType: 1,
      logoFile: '',
      logoFileBack: '',
      logoFileType: null,
      logoFileBackType: null,
      alignText: "Gauche",
      logoWidth: 300,
      backLogoWidth: 100,
      selectedColorIndex: -1,
      selectedColor: 'white',
      selectedColorCode: '',
      cardFirstName: '',
      cardlastName: '',
      cardJobTitle: '',
      cardJob: '',
      cardPhoneNumber: '',
      cardFullName: '',
      cardPhoneNumberFix: '',
      cardAddress: '',
      cardAddress2: null,
      cardZipCode: null,
      cardCity: null,
      cardCountry: '',
      cardEmail: '',
      cardFax: '',
      cardWebsite: '',
      cardCompanyName: '',
      deliveryAddress1: '',
      deliveryAddress2: '',
      //new fields for delivery
      deliveryFirstName: '',
      deliveryLastName: '',
      deliveryCompanyName: '',
      deliveryCity: '',
      deliveryZipCode: '',
      deliveryCountry: 'FRANCE',
      billingFirstName: '',
      billingLastName: '',
      billingCompanyName: '',
      billingAddress1: '',
      billingAddress2: '',
      billingCity: '',
      billingZipCode: '',
      billingCountry: 'FRANCE',
      billingIsSameAsDelivery: false,
      userPicture: '',
      isUserConnected: false,
      userTextureFile: null,
      selectedFont: 'Nordica',
      selectedFontIndex: 0,
      selectedFontSize: 10,
      isCommandMultiple: this.props.location.state && this.props.location.state.orderType == "person" ? false : true,
      cardUsers: null,
      firstButtonActivated: false,
      isModalVisible: false,
      cardFrontFace: "",
      cardBackFace: "",
      orderPromoCode: null,
      promoCodeValid: null,
      promoCodeDiscount: 0,
      promoCodeFreeDelivery: false,
      orderComment: "",
      orderFdpPrice: 0,
      orderPrice: 0,
      isResetPasswordModalVisible: false,
      isModalConnexionVisible: false,
      isModalRegisterVisible: false,
      isSnackbarVisible: false,
      snackbarSeverity: '',
      snackbarMessage: '',
      isLoaderVisible: false,
      email: '',
      password: '',
      cards: [],
      selectedCard: null,
      transparentLogo: false,
      isLoaderOrderVisible: false,
      pricesData: null,
      canGoToValidation: false,
    };
    this.setState(this.state);
  }

  _getMe = async () => {
    const res = await Services.getMe();

    if (res.account) {
      return {
        email: res.account.email,
        firstName: res.account.firstName,
        lastName: res.account.lastName,
        address: res.address1,
        additionalAddress: res.address2,
        phoneNumber: res.account.phone,
        city: res.city,
        postalCode: res.zipCode,
        company: res.company,
        promoCode: res.promoCode
      }
    }
  }

  _getMyCards = async () => {
    const res = await Services.getMyCards();

    if (res._values) {
      let cards = []
      res._values.data.forEach(i => {
        cards.push(i._values)
      });
      this.setState({cards: cards})
    }
  }

  _saveCardFace = (face, ctx, callback) => {
    window.html2canvas = html2canvas;
    html2canvas(document.getElementById('pdf-element'), {scale: 3}).then(canvas => {
      var a = document.createElement('a');
      a.href = canvas.toDataURL();
      a.download = 'BAT.png';
      if (face === "front") {
        this.setState({cardFrontFace: canvas}, function () {
          callback(ctx);
        });
      } else if (face === "back") {
        this.setState({cardBackFace: canvas}, function () {
          callback(ctx);
        });
      }
      //a.click();
    });
  }

  _saveBackCardFace = () => {
    window.html2canvas = html2canvas;
    html2canvas(document.getElementById('pdf-element'), {scale: 3}).then(canvas => {
      var a = document.createElement('a');
      a.href = canvas.toDataURL();
      a.download = 'BAT.png';
      this.setState({cardBackFace: canvas});
      this._updateCommandStep('next');
      //a.click();
    });
  }

  //function called on pageLoad and resize
  _updateDimension = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    this.setState({screenWidth: screenWidth});
    this.setState({screenHeight: screenHeight});
    /*
    if (screenWidth > 1000 && this.state.logoFile === "") {
      this.setState({logoWidth: 350});
    } else {
      this.setState({logoWidth: 100});
    }
    */
  }

  //function used to save the user type (@param : string 'user' / 'company')
  _handleCommandType = (type) => {
    this.setState({userType: type});
    this._updateCommandStep('next');
  }

  //called to change card oritentation
  _changeCardOrientation = (newOrientation) => {
    if (newOrientation === 'vertical') {
      this.setState({isCardHorizontal: false});
      this.setState({isCardVertical: true});
    } else {
      this.setState({isCardHorizontal: true});
      this.setState({isCardVertical: false});
    }
  }

  //function used to update the state of command
  _updateCommandStep = (cmd) => {
    let commandStep = this.state.commandStep;

    //go to next step
    if (cmd === 'next') {
      commandStep++;

      if (commandStep > this.state.lastStep) {
        this.setState({lastStep: commandStep});
      }

      if (commandStep === 1) {
        window.scrollTo(0, 0);
      }

      //no texture available for wood card base
      if (commandStep === 4) {
        if (this.state.selectedCardBaseColor === "else") {
          commandStep++;
        }
      }
      //no color available except for white card base
      if (commandStep === 5) {
        if (this.state.selectedCardBaseColor !== "white") {
          commandStep++;
        }
      }
      //animation rotate card
      if (commandStep === 6) {
        this._rotateCard(true);
      }

      if (commandStep === 7) {
        this._getPrices();
      }
    }

    //go to previous state
    else if (cmd === 'prev') {

      if (commandStep > 0) {
        //no texture available for wood card base
        if (commandStep === 4) {
          this.setState({userTextureFile: null});
          this.setState({selectedTextureIndex: -1});
        }
        if (commandStep === 4) {
          if (this.state.selectedCardBaseColor === "else") {
            commandStep--;
          }
        }
        commandStep--;

        if (commandStep === 1) {
          window.scrollTo(0, 0);
        }
        //animation rotate card
        if (commandStep === 5) {
          this._rotateCard(true);
          //no color available except for white card base
          if (this.state.selectedCardBaseColor !== "white") {
            commandStep--;
          }
        }
      }
    }
    this.setState({commandStep: commandStep});
  }

  //rotate card function
  _rotateCard = (saveBat) => {
    //get next card face (string 'front' / 'back')
    const nextCardFace = this._getNewCardFace();
    if (saveBat) {
      this._saveCardFace(this.state.cardFaceDisplayed, this, async function (ctx) {
        ctx._middleUpdate();
        //start animation
        ctx.setState({isActivated: true});
        await sleep(1500);
        //stop animation & reverse card face
        ctx.setState({isActivated: false, cardFaceDisplayed: nextCardFace}, () => {
          ctx.setState({canGoToValidation: true});
        });
      });
    }
  }

  //function called to get the next card face
  _getNewCardFace = () => {
    const currentState = this.state.cardFaceDisplayed;
    if (currentState === 'front') {
      return 'back';
    } else if (currentState === 'back') {
      return 'front';
    }
  }

  //function used to update cards data and background during animation
  _middleUpdate = async () => {
    await sleep(150);
    //update card background color
    let backgroundColor = this.state.backgroundColor;
    if (backgroundColor === 'white') {
      backgroundColor = 'white';
    } else {
      backgroundColor = 'black';
    }
    //empty card data while animation is running
    const currentState = '';
    this.setState({cardFaceDisplayed: currentState});
    this.setState({backgroundColor: backgroundColor});

    // Recalcul fullName
    if (this.state.cardFirstName) {
      this._handleInputChangeForCardContact('cardFirstName', this.state.cardFirstName);
    }
  }

  //function user to get the animated card dimention (@param: string 'whidth' / 'height')
  _getCardDimention = (value) => {
    let retValue = 0;
    if (value === 'width') {
      this.state.isCardHorizontal ? retValue = 550 : retValue = 350;
    } else {
      this.state.isCardHorizontal ? retValue = 350 : retValue = 550;
    }
    return retValue;
  }

  //called when a texture is clicked on grid
  _textureClicked = (index) => {
    this.setState({userTextureFile: null}, function () {
      this.setState({selectedTextureIndex: index});

      switch (index) {
        case 0:
          if (this.state.selectedCardBaseColor === 'black') {
            this.setState({userTextureFile: "premiumBlackWave.svg"});
          } else {
            this.setState({userTextureFile: "fog.svg"});
          }
          break;
        case 1:
          this.setState({userTextureFile: "line.svg"});
          break;
        case 2:
          this.setState({userTextureFile: "marble.svg"});
          break;
        case 3:
          this.setState({userTextureFile: "roseBlue.svg"});
          break;
        case 4:
          this.setState({userTextureFile: "roseGold.svg"});
          break;
        case 5:
          this.setState({userTextureFile: "tile.svg"});
          break;
      }
    });
  }

  //called when font is clicked on grid
  _fontClicked = (index) => {
    this.setState({selectedFontIndex: index});
    this.setState({selectedFont: fontFamily[index].fontName});
    this.setState({selectedFontSize: fontFamily[index].fontSize});
  }

  //called when color is clicked on grid
  _colorClicked = (index, color, code) => {
    this.setState({selectedColorIndex: index});
    this.setState({selectedColor: color});
    this.setState({selectedColorCode: code});
  }

  //called to set user logo file into state
  _saveLogoFile = (file, face, type) => {
    if (face === 'front') {
      this.setState({logoFile: file});
      this.setState({logoFileType: type});
      this.setState({logoFileBack: file});
      this.setState({logoFileBackType: type});
    } else {
      this.setState({logoFileBack: file});
      this.setState({logoFileBackType: type});
    }
  }

  _saveUserPicture = async (fileName) => {
    this.setState({userPicture: fileName});
  }

  _saveCardUsers = async (cardUsers) => {
    this.setState({cardUsers: cardUsers}, function () {
      this._checkInputForContact();
    });
  }

  //called to set user texture file into state
  _saveUserTextureFile = (file) => {
    this.setState({userTextureFile: null}, function () {
      this.setState({userTextureFile: file});
      this.setState({selectedTextureIndex: 1000});
    });
  }

  //called when user update logo size with slider
  _updateLogoSize = (value, type) => {
    if (type === "front") {
      this.setState({logoWidth: value});
    } else {
      this.setState({backLogoWidth: value});
    }
  }

  _handleFullName(stateName, value) {
    let tmp = "";
    const maxSizeOfRow = 20;
    //handle first name
    if (stateName === "cardFirstName") {
      //limit name to 20 char
      if (value.length > maxSizeOfRow) {
        value = value.substring(0, maxSizeOfRow);
      }
      const length = value.length + this.state.cardlastName.length;
      //first and last name on one line
      if (length < maxSizeOfRow) {
        tmp = value + " " + this.state.cardlastName;
      }
      //first and last name on two lines
      else {
        tmp = value + "\n" + this.state.cardlastName;
      }
      this.setState({cardFullName: tmp});
      this.setState({cardFirstName: value});
    }

    //handle card last name
    else if (stateName === "cardlastName") {
      //limit name to 20 char
      if (value.length > maxSizeOfRow) {
        value = value.substring(0, maxSizeOfRow);
      }
      const length = value.length + this.state.cardFirstName.length;
      //first and last name on one line
      if (length < maxSizeOfRow) {
        tmp = this.state.cardFirstName + " " + value;
      }
      //first and last name on two lines
      else {
        tmp = this.state.cardFirstName + "\n" + value;
      }
      this.setState({cardFullName: tmp});
      this.setState({cardlastName: value});
    } else if (stateName === "cardJobTitle") {
      const length = value.length;
      //2 lines
      if (length > maxSizeOfRow + 10 && length < (maxSizeOfRow - 10) * 2) {
        value = value.substring(0, maxSizeOfRow) + "\n" + value.substring(maxSizeOfRow);
      }
      //one line do nothing but save state
      this.setState({cardJobTitle: value});
    } else if (stateName === "cardPhoneNumber") {
      value = value.substring(0, maxSizeOfRow);
      this.setState({cardPhoneNumber: value});
    }
  }

  //function used to handle change on card contact and check length
  _handleInputChangeForCardContact = (stateName, value) => {

    if (stateName === "cardlastName" || stateName === "cardFirstName" || stateName === "cardJobTitle" || stateName === "cardPhoneNumber") {
      this._handleFullName(stateName, value);
    } else {
      this.setState({[stateName]: value}, function () {
        this._checkInputForContact(stateName, value);
      });
    }
  }

  //handle TextField change
  _handleInputChange = (stateName, value) => {
    this.setState({[stateName]: value}, function () {
      this._checkInputForContact();
    });

    if (stateName === "deliveryCountry") {
      this._getPrices();
    }
    if (stateName == "cardJob") {
      this.setState({cardJobTitle: value});
    }
  }

  //used to display or hide image bank modal
  _handleImageBankModal = () => {
    const currentState = this.state.displayImageBankModal;
    this.setState({displayImageBankModal: !currentState});
  }

  //used to display or hide image bank modal
  _handleQrcodeModal = async () => {
    if (this.state.displayQrcodeModal == false) {
      const ret = await Services.getQrcodeForUpload();
      console.log(ret.id)
      this.setState({qrCode: ret.qrcode}, function() {
        this.setState({qrCodeId: ret.id});
        const currentState = this.state.displayQrcodeModal;
        this.setState({displayQrcodeModal: !currentState});
      });
    }
    else {
      const currentState = this.state.displayQrcodeModal;
      this.setState({displayQrcodeModal: !currentState});
    }
  }

  _handleCheckoutInputChange = (stateName, value) => {
    this.setState({[stateName]: value});

    if (stateName === "deliveryCountry") {
      this._getPrices();
    }
  }

  _getMarginForBackCard = (state) => {
    if (state === "fullName") {
      return 0;
    }
    if (state === "jobTitle") {
      if (this.state.cardFullName.length > 18) {
        return 10;
      } else {
        return -20;
      }
    }
    if (state === "phoneNumber") {
      if (this.state.cardJobTitle.length < 33) {
        return -20;
      } else if (this.state.cardJobTitle.length > 33 && this.state.cardJobTitle.length < 36) {
        return 0;
      } else {
        return 10;
      }
    }
  }

  //used to save the selected card base into class state
  _handleCardBase = (selectedIndex) => {
    if (selectedIndex == 1) {
      this.setState({selectedCardBaseColor: 'black'});
      this.setState({backgroundColor: 'black'});
      this.setState({selectedColor: "white"});
      this.setState({selectedCardType: 1});
    } else if (selectedIndex === 0) {
      this.setState({selectedCardBaseColor: 'white'});
      this.setState({backgroundColor: 'white'});
      this.setState({selectedColor: "black"});
      this.setState({selectedCardType: 2});
    } else {
      this.setState({selectedCardBaseColor: 'else'});
      this.setState({selectedColor: "black"});
      this.setState({backgroundColor: "white"});
      this.setState({selectedCardType: 5});
    }
    this.setState({selectedCardBase: cardBase[selectedIndex]});
    this.setState({selectedCardBaseIndex: selectedIndex});
  }

  //called when user cliks on I oder for multiple persons row
  _onPersonnalInformationRowClick = () => {
    this.setState({isCommandMultiple: !this.state.isCommandMultiple});
  }

  //function used to handle command step when user clicks on stepper button
  _handleStepperClick = (index) => {
    if (this.state.commandStep < index) {
      return;
    }
    if (index === 5 && this.state.selectedCardBaseColor !== "white") {
      return;
    }

    if (index <= this.state.lastStep) {
      let commandStep = this.state.commandStep;
      const previewState = commandStep;
      let saveBat = previewState == 7 ? false : true;
      commandStep = index;
      if (previewState <= 5 && commandStep >= 6) {
        this._rotateCard(saveBat);
      } else if (previewState >= 6 && commandStep <= 5) {
        this._rotateCard(saveBat);
      }
      if (commandStep == 7) {
        this._getPrices();
      }
      let canGoToValidation = index === 6; // si on retourne sur 6, pas d'animation exécutée, on peut continuer
      this.setState({commandStep: commandStep, canGoToValidation});
    }
    else {
      return;
    }
  }


  _updateModalState = () => {

  }

  //function used to check if all fields are filled before to activate the next step button
  _checkInputForContact() {
    this.setState({firstButtonActivated: false});
    if ((!this.state.isCommandMultiple && (this.state.cardFirstName === "" || this.state.cardlastName === "" || this.state.cardEmail === ""))
      || (this.state.isCommandMultiple && !this.state.cardUsers)) {
      return;
    }
    this.setState({firstButtonActivated: true});
    return;
  }

  //called when login button clicked
  _loginButtonClicked = async () => {
    //check if fields are filled
    if (!this._checkForEmptyFields()) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de remplir l'ensemble des champs"});
      this._handleSnackbarState();
      await sleep(2500);
      this._handleSnackbarState();
      return;
    }
    //check if email format is ok
    if (!Security.isEmailFormatOk(this.state.email)) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Merci de renseigner un email valide"});
      this._handleSnackbarState();
      await sleep(2500);
      this._handleSnackbarState();
      return;
    }

    this.setState({isLoaderVisible: true});
    await this.handleLogin()

    /*const loginSuccess = await Services.login(JSON.stringify(data));
    if (loginSuccess) {
      alert('OK');
      ReactDOM.render(<Orders />, document.getElementById('root'));
    }
    else {
      alert('KO');
    }
    this.setState({isLoaderVisible: false});*/
  }

  _loginFromRegister = async (credentialsObject) => {
    this.setState({isLoaderVisible: true});
    await this.handleLogin({username: credentialsObject.email, password: credentialsObject.password})
  }

  handleLogin = async (data = {
    username: this.state.email,
    password: this.state.password
  }) => {
    let res = await Services.login(JSON.stringify(data));
    if (!res.success) {
      this.setState({snackbarSeverity: 'error'});
      this.setState({snackbarMessage: "Identifiants invalides"});
      this.setState({isLoaderVisible: false})
      this._handleSnackbarState();
      await sleep(1500);
      this._handleSnackbarState();
    } else {
      localStorage.setItem("isUserConnected", "true");
      localStorage.setItem("token", res.token);
      localStorage.setItem("account", JSON.stringify(res.account));
      this.setState({snackbarSeverity: 'success'});
      this.setState({snackbarMessage: "Connexion réussie"});
      this._handleSnackbarState();
      await sleep(1500);
      this._handleSnackbarState();
      this.setState({isUserConnected: true})
      this.setState({isLoaderVisible: false})
      this.setState({
        isModalConnexionVisible: false,
        isModalRegisterVisible: false,
        isResetPasswordModalVisible: false
      });
    }
  }

  _userNeedsSomeHelp = () => {
    const currentState = this.state.isModalVisible;
    this.setState({isModalVisible: !currentState});
  }

  _handleModalState = () => {
    const currentState = this.state.isModalVisible;
    this.setState({isModalVisible: !currentState});
  }
  _handleModalStateConnexion = () => {
    const currentState = this.state.isModalConnexionVisible;
    this.setState({isModalConnexionVisible: !currentState});
  }
  _handleModalStateRegister = () => {
    const currentState = this.state.isModalRegisterVisible;
    this.setState({isModalRegisterVisible: !currentState, isModalConnexionVisible: false});
  }

  _handleTextAlign = (checkbox) => {
    this.setState({alignText: checkbox});
  }
  _onChangeTransparentLogo = (e) => {
    this.setState({transparentLogo: !this.state.transparentLogo});
  }

  _requestConnexion = () => {
    this.setState({isModalConnexionVisible: true})
  }
  _requestRegister = () => {
    this.setState({isModalConnexionVisible: false, isModalRegisterVisible: true})
  }

  _convertBlobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        var dataUrl = reader.result;
        resolve(dataUrl.split(',')[1]);
      };
    });
  };

  _confirmCheckout = async () => {
    this.setState({isLoaderOrderVisible: true});

    // Convert logos and user texture from blob to base64
    var reader = new FileReader();
    let logoBase64 = "";
    let logo2Base64 = "";
    let userTexture = null;
    if (this.state.logoFile) {
      let blob = await fetch(this.state.logoFile).then(r => r.blob());
      logoBase64 = await this._convertBlobToBase64(blob);
    }
    if (this.state.logoFileBack) {
      let blob = await fetch(this.state.logoFileBack).then(r => r.blob());
      logo2Base64 = await this._convertBlobToBase64(blob);
    }
    if (this.state.selectedTextureIndex == 1000 && this.state.userTextureFile) {
      let blob = await fetch(this.state.userTextureFile).then(r => r.blob());
      userTexture = await this._convertBlobToBase64(blob);
    }

    const data = {
      delivery_first_name: this.state.deliveryFirstName,
      delivery_last_name: this.state.deliveryLastName,
      delivery_company_name: this.state.deliveryCompanyName,
      billing_first_name: this.state.billingFirstName,
      billing_last_name: this.state.billingLastName,
      billing_company_name: this.state.billingCompanyName,
      billing_is_same_as_delivery: this.state.billingIsSameAsDelivery,
      city_delivery: this.state.deliveryCity,
      address_1_delivery: this.state.deliveryAddress1,
      address_2_delivery: this.state.deliveryAddress2,
      zip_code_delivery: this.state.deliveryZipCode,
      country_delivery: this.state.deliveryCountry,
      address_1_billing: this.state.billingAddress1,
      address_2_billing: this.state.billingAddress2,
      zip_code_billing: this.state.billingZipCode,
      country_billing: this.state.billingCountry,
      city_billing: this.state.billingCity,
      address_1: this.state.cardAddress,
      address_2: this.state.cardAddress2,
      zip_code: this.state.cardZipCode,
      city: this.state.cardCity,
      country: this.state.cardCountry,
      orientation: this.state.isCardVertical ? 1 : 2,
      card_type: this.state.selectedCardType,
      card_users: this.state.cardUsers,
      texture: userTexture ? userTexture : this.state.userTextureFile,
      is_company: this.state.isCommandMultiple,
      last_name: this.state.cardlastName,
      first_name: this.state.cardFirstName,
      position: this.state.cardJob,
      positionCard: this.state.cardJobTitle,
      company: this.state.cardCompanyName,
      email: this.state.cardEmail,
      web_site: this.state.cardWebsite,
      phone_1: this.state.cardPhoneNumber,
      phone_2: this.state.cardPhoneNumberFix,
      fax: this.state.cardFax,
      logo: logoBase64,
      logo2: logo2Base64,
      logoType: this.state.logoFileType,
      logo2Type: this.state.logoFileBackType,
      avatar: this.state.userPicture,
      color_code: this.state.selectedColorCode,
      promo_code: this.state.orderPromoCode,
      comment: this.state.orderComment,
      batFront: this.state.cardFrontFace ? this.state.cardFrontFace.toDataURL().split(';base64,')[1] : '',
      batBack: this.state.cardBackFace ? this.state.cardBackFace.toDataURL().split(';base64,')[1] : ''
    };

    let res = await Services.checkout(JSON.stringify(data));

    this.setState({isLoaderOrderVisible: false});

    if (res.success) {
      if (this.state.isCommandMultiple) {
        window.location.href = "/checkout_result/success";
      } else {
        if (res.sessionCheckout) {
          const stripe = await loadStripe(res.stripe_pk);
          return stripe.redirectToCheckout({sessionId: res.sessionCheckout});
        } else {
          window.location.href = "/checkout_result/success";
        }
      }
    } else {
      alert("Erreur lors de l'enregistrement de votre commande");
    }
  }

  _getClientSecret = (selectedCard) => {
    //todo
    // console.log(selectedCard)

  }

  //modal && snacks
  //function called to open or close snackbar
  //called when register button is clicked
  _registerButtonClicked = () => {
    //display register page
    // this.props.history.push('/inscription');
    this._requestRegister()
  }

  //called when forgot password button clicked
  _forgotPasswordButtonClicked = () => {
    this._handleResetPasswordModalState();
  }


  //display forgot password modal
  _handleResetPasswordModalState = () => {
    const currentState = this.state.isResetPasswordModalVisible;
    this.setState({
      isResetPasswordModalVisible: !currentState,
      isModalConnexionVisible: !this.state.isModalConnexionVisible
    });
  }

  //used to check empty fields
  _checkForEmptyFields = () => {
    if (this.state.email === '' || this.state.password === '') {
      return false;
    }
    return true;
  }

  //function called to open or close snackbar
  _handleSnackbarState = () => {
    const currentState = this.state.isSnackbarVisible;
    this.setState({isSnackbarVisible: !currentState});
  }

  _getCheckPromoCode = async (code) => {
    this.setState({promoCodeValid: null});
    const promoCode = await Services.getCheckPromoCode(code);
    if (promoCode.success) {
      this.setState({promoCodeValid: true});
      this.setState({promoCodeDiscount: promoCode.discount});
      if (promoCode.freeDeliveries == true) {
        this.setState({promoCodeFreeDelivery: true});
      } else {
        this.setState({promoCodeFreeDelivery: false});
      }
    } else {
      this.setState({promoCodeValid: false});
      this.setState({promoCodeDiscount: 0});
      this.setState({promoCodeFreeDelivery: false});
    }
    this._getPrices();
  }

  _getPrices = async () => {
    const prices = await Services.getPrices();
    let price = 0;

    if (prices) {
      const priceCardType = prices.cardTypes.find(e => e.id === this.state.selectedCardType);
      if (priceCardType) {
        price += priceCardType.priceHt;
      } else {
        console.log("Prix introuvable pour ce type de carte");
      }

      if (this.state.userTextureFile) {
        const priceTexture = prices.textures.find(e => e.texture === this.state.userTextureFile);
        if (priceTexture) {
          price += priceTexture.priceHt;
        } else {
          price += prices.textures_others;
        }
      }

      let fdpPrice = 0;
      if (!this.state.isCommandMultiple) {
        if (!this.state.promoCodeFreeDelivery) {
          if (this.state.deliveryCountry && this.state.deliveryCountry.toUpperCase() == "FRANCE") {
            fdpPrice = prices.deliveries.FRANCE;
          } else {
            fdpPrice = prices.deliveries.OTHERS;
          }
        }
      }

      if (this.state.promoCodeValid == true && this.state.promoCodeFreeDelivery == true) {
        fdpPrice = 0;
      }

      this.setState({orderFdpPrice: fdpPrice});

      if (this.state.promoCodeValid == true) {
        price = (price * (1 - this.state.promoCodeDiscount));
      }

      this.setState({orderPrice: price});
    }
  }

  //function called to open or close snackbar
  _setCanGoToValidationState = (bool) => {
    this.setState({canGoToValidation: bool});
  }

  render() {
    return (
      <div>
        <div
          className='container'
          style={{flexDirection: 'column'}}
        >
          <AppBar menuClicked={this._menuClicked} {...this.state}/>
          <h1 style={this.state.isUserConnected ? {marginTop: 150, color: "#003939"} : {
            marginTop: 20,
            color: "#003939"
          }}>Nouvelle commande</h1>
          {
            this.state.screenWidth < 1000
              ?
              //smartphone version
              <div>
                <RightComponentsHanlder
                  {...this.state}
                  getMarginForBackCard={this._getMarginForBackCard}
                  cardRef={this._cardRef}
                  getCardDimention={this._getCardDimention}
                  requestLogin={this._requestConnexion}
                  confirmCheckout={this._confirmCheckout}
                  handleInputChange={this._handleInputChange}
                  handleCheckoutInputChange={this._handleCheckoutInputChange}
                  savedCards={this.state.cards}
                  selectedCard={this.state.selectedCard}
                  getPrices={this._getPrices}
                  getClientSecret={this._getClientSecret}
                  getCheckPromoCode={this._getCheckPromoCode}
                  getSelectedCard={(id) => {
                    this.setState({selectedCard: id})
                  }}
                />
                <div id='pdf-element' className='flex-container' style={{marginTop: '50px'}}>
                  <LeftComponentsHanlder
                    {...this.state}
                    setCanGoToValidationState={this._setCanGoToValidationState}
                    importPicture={this._saveUserPicture}
                    saveUserTextureFile={this._saveUserTextureFile}
                    handleInputChangeForCardContact={this._handleInputChangeForCardContact}
                    commandType={this._handleCommandType}
                    updateCommandStep={this._updateCommandStep}
                    changeCardOrientation={this._changeCardOrientation}
                    screenHeight={this.state.screenHeight * 0.70}
                    handleCardBase={this._handleCardBase}
                    textureClicked={this._textureClicked}
                    saveLogoFile={this._saveLogoFile}
                    updateLogoSize={this._updateLogoSize}
                    requestLogin={this._requestConnexion}
                    colorClicked={this._colorClicked}
                    handleInputChange={this._handleInputChange}
                    fontClicked={this._fontClicked}
                    saveCardFace={this._saveCardFace}
                    handleTextAlign={this._handleTextAlign}
                    transparentLogo={this.state.transparentLogo}
                    onChangeTransparentLogo={this._onChangeTransparentLogo}
                    saveBackCardFace={this._saveBackCardFace}
                    onPersonnalInformationRowClick={this._onPersonnalInformationRowClick}
                    saveCardUsers={this._saveCardUsers}
                  />
                </div>
              </div>
              :
              //desktop version
              <div>
                <Stepper
                  stepperWidth={this.state.screenWidth * 0.9}
                  updateCommandStep={this._updateCommandStep}
                  commandStep={this.state.commandStep}
                  handleStepperClick={this._handleStepperClick}
                  userNeedsSomeHelp={this._userNeedsSomeHelp}
                  isCommandMultiple={this.state.isCommandMultiple}
                />
                <div style={{
                  width: "100%",
                  //height: this.state.screenHeight * 0.75,
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 60
                }}>
                  {/*left activity container */}
                  <div style={{width: "44%"}}>
                    <LeftComponentsHanlder
                      {...this.state}
                      importPicture={this._saveUserPicture}
                      saveUserTextureFile={this._saveUserTextureFile}
                      handleInputChangeForCardContact={this._handleInputChangeForCardContact}
                      commandType={this._handleCommandType}
                      updateCommandStep={this._updateCommandStep}
                      changeCardOrientation={this._changeCardOrientation}
                      screenHeight={this.state.screenHeight * 0.70}
                      handleCardBase={this._handleCardBase}
                      textureClicked={this._textureClicked}
                      saveLogoFile={this._saveLogoFile}
                      updateLogoSize={this._updateLogoSize}
                      colorClicked={this._colorClicked}
                      requestLogin={this._requestConnexion}
                      handleInputChange={this._handleInputChange}
                      fontClicked={this._fontClicked}
                      saveCardFace={this._saveCardFace}
                      handleTextAlign={this._handleTextAlign}
                      transparentLogo={this.state.transparentLogo}
                      onChangeTransparentLogo={this._onChangeTransparentLogo}
                      saveBackCardFace={this._saveBackCardFace}
                      onPersonnalInformationRowClick={this._onPersonnalInformationRowClick}
                      saveCardUsers={this._saveCardUsers}
                    />
                  </div>
                  {/*right activity container*/}
                  <div className='flex-container' style={{width: "44%"}}>
                    <RightComponentsHanlder
                      {...this.state}
                      getMarginForBackCard={this._getMarginForBackCard}
                      cardRef={this._cardRef}
                      getCardDimention={this._getCardDimention}
                      requestLogin={this._requestConnexion}
                      confirmCheckout={this._confirmCheckout}
                      handleInputChange={this._handleInputChange}
                      handleCheckoutInputChange={this._handleCheckoutInputChange}
                      savedCards={this.state.cards}
                      getPrices={this._getPrices}
                      selectedCard={this.state.selectedCard}
                      getCheckPromoCode={this._getCheckPromoCode}
                      getClientSecret={this._getClientSecret}
                      getSelectedCard={(id) => {
                        this.setState({selectedCard: id})
                      }}
                    />
                  </div>
                </div>
              </div>
          }
          {/*Bottom card creation stepper*/}
          {
            //smartphone version
            this.state.screenWidth < 1000
              ?
              <SmartphoneStepper
                updateCommandStep={this._updateCommandStep}
                commandStep={this.state.commandStep}
                isCommandMultiple={this.state.isCommandMultiple}
              />
              :
              null
          }
          {/*Help modal*/}
          <Modal
            isModalVisible={this.state.isModalVisible}
            handleModalState={this._handleModalState}
          />

          <ConnexionModal
            isModalVisible={this.state.isModalConnexionVisible}
            isSnackbarVisible={this.state.isSnackbarVisible}
            isLoaderVisible={this.state.isLoaderVisible}
            handleModalState={this._handleModalStateConnexion}
            handleInputChange={this._handleInputChange}
            loginButtonClicked={this._loginButtonClicked}
            registerButtonClicked={this._registerButtonClicked}
            forgotPasswordButtonClicked={this._handleResetPasswordModalState}
            handleSnackbarState={this._handleSnackbarState}
            onchangePwd={(pwd) => {
              this.setState({password: pwd})
            }}
            onchangeMail={(m) => {
              this.setState({email: m})
            }}
            lottieSpinnerOption={lottieSpinnerOption}
          />

          <RegisterModal
            isModalVisible={this.state.isModalRegisterVisible}
            isSnackbarVisible={this.state.isSnackbarVisible}
            isLoaderVisible={this.state.isLoaderVisible}
            handleModalState={this._handleModalStateRegister}
            handleInputChange={this._handleInputChange}
            forgotPasswordButtonClicked={this._handleResetPasswordModalState}
            handleSnackbarState={this._handleSnackbarState}
            proceedToLogin={this._loginFromRegister}
            lottieSpinnerOption={lottieSpinnerOption}
          />

          {/*Forgot password modal*/}
          <ModalPassword isResetPasswordModalVisible={this.state.isResetPasswordModalVisible}
                         handleResetPasswordModalState={this._handleResetPasswordModalState}/>

          {
            //alert snackbar
            this.state.isSnackbarVisible ?
              <SnackBar handleClose={this._handleSnackbarState} severity={this.state.snackbarSeverity}
                        customStyle={{zIndex: 49599}}
                        message={this.state.snackbarMessage}/> : null
          }
          {
            //spinning loader
            this.state.isLoaderOrderVisible ?
              <div className='loader' style="width: 20%;"><Lottie options={lottieSpinnerOption}
                                                                  isClickToPauseDisabled={true}/>
              </div> : null
          }
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(NewOrder);
