import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    marginTop: '2em',
    marginBottom: '2em',
  },
});

const CardLogoSizeSlider = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-small-steps" gutterBottom>
        Taille du logo
      </Typography>
      <span className="editThumbSize">
        <Slider
          style={{color: '#003732'}}
          onChange={(_, value) => props.updateLogoSize(value, props.type)}
          defaultValue={props.sliderDefaultValue}
          step={30}
          marks
          min={10}
          max={1000}
        />
      </span>
    </div>
  );
}

export default CardLogoSizeSlider;
