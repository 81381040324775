//function used to test password strongness
export function isPasswordStrongEnough(password, confirmation) {
  //check if password and password confirmation are the same
  if (password !== confirmation) {
    return false;
  }
  //check if password is at least 8 chars long
  if (password.length < 11) {
    return false;
  }
  //check if password has at least one special char
  let regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (regex.test(password) === false) {
    return false;
  }
  //check if password has at least 1 number
  regex = /([0-9].*[a-z])|([a-z].*[0-9])/;
  if (regex.test(password) === false) {
    return false;
  }
  //check if password contains at least one uppercase char
  regex = /\b[A-Z]+\b/;
  if (regex.test(password) === false) {
    return false;
  }
  return true;
}

//function used to validate user email
export function isEmailFormatOk(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}
