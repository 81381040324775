export const getToken = () => {
  return localStorage.getItem('token');
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
}
export const getAccount = () => {
  return JSON.parse(localStorage.getItem('account'));
}
export const setUser = (user) => {
  return localStorage.setItem('user', JSON.stringify(user));
}
export const setAccount = (acc) => {
  return localStorage.setItem('user', JSON.stringify(acc));
}