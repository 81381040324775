import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Fog from "../../assets/images/textures/FOG.jpg";
import Line from "../../assets/images/textures/LINE.jpg";
import Marble from "../../assets/images/textures/MARBLE.jpg";
import RoseBlue from "../../assets/images/textures/ROSEBLUE.jpg";
import RoseGold from "../../assets/images/textures/ROSE-GOLD.jpg";
import Tile from "../../assets/images/textures/TILE.jpg";
import BlackWave from "../../assets/images/textures/WHITE-WAVE.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  rootMin: {
    display: 'flex',
    overflow: 'hidden',
    width: "18em"
  },
  gridList: {
    width: '100%'
  },
}));

 const tileDataWhite = [
   {
     img: Fog
   },
   {
     img: Line
   },
   {
     img: Marble
   },
   {
     img: RoseBlue,
   },
   {
     img: RoseGold,
   },
   {
     img: Tile,
   },
 ];

 const tileDataBlack = [
  {
    img: BlackWave
  }
];

const CardGrid = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{marginBottom: 15}}>
      <GridList cellHeight={160} className={classes.gridList} cols={props.screenWidth > 1000 ? 3 : 2}>
        <GridListTile key={-1} cols={1} onClick={() => props.textureClicked(-1)} style={props.selectedTextureIndex === -1 ? {opacity: '100%'} : {opacity: '50%'}} id='textureGridItem' className="pointer">
          <p style={{textAlign: 'center', fontSize: 12, marginTop: '50%',color: 'grey'}}>Supprimer la<br/>texture</p>
        </GridListTile>
        {props.selectedCardBaseColor === 'white' && tileDataWhite.map((tile, index) => (
          <GridListTile key={index} cols={1}>
            <img onClick={() => props.textureClicked(index)} id='textureGridItem' className="pointer" src={tile.img} style={props.selectedTextureIndex === index ? {opacity: '100%'} : {opacity: '50%'}}/>
          </GridListTile>
        ))}
        {props.selectedCardBaseColor === 'black' && tileDataBlack.map((tile, index) => (
          <GridListTile key={index} cols={1}>
            <img onClick={() => props.textureClicked(index)} id='textureGridItem' className="pointer" src={tile.img} style={props.selectedTextureIndex === index ? {opacity: '100%'} : {opacity: '50%'}}/>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

export default CardGrid;
