import React, { useState, useRef } from 'react';
import ImportButton from '../buttons/importLogoButton.js';

const ImportLogoFileExplorer = (props) => {
  const [image, setImage] = useState('');
  const inputFile = useRef(null);

  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const logoFile = (window.URL || window.webkitURL).createObjectURL(files[0]);

      if (files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg") {
        props.saveLogoFile(logoFile, props.face, files[0].type);
      } else {
        alert("Format de fichier non pris en charge");
      }
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    props.isSmartphoneVersion
    ?
    <div style={{}}>
      <input
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
        <div style={{}}>
          <div className="hoverButtonImport pointer">
            <ImportButton isSmartphoneVersion={true} buttonInfos={"(JPG ou PNG)"} onClick={() => onButtonClick()} buttonName={props.face == 'front' ? 'Importer mon logo' : 'Importer un autre logo'} primaryColor='true'/>
          </div>
        </div>
    </div>
    :
    <div style={{display: "flex", justifyContent: "left"}}>
      <input
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
      <div style={{marginTop: '2%'}}>
        <ImportButton buttonInfos={"(JPG ou PNG)"} onClick={() => onButtonClick()} buttonName={props.face == 'front' ? 'Importer mon logo' : 'Importer un autre logo'} primaryColor='true'/>
      </div>
    </div>
  );
};

export default ImportLogoFileExplorer;
