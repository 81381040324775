import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

 const ImportTextureButton = (props) => {
  return (
    <div onClick={() => props.onClick()} className="pointer" style={{width: "100%", textAlign: 'center'}}>
      <span className="c-article__link">{props.buttonName}</span>
      <p >{props.buttonInfos}</p>
    </div>
  );
}
export default ImportTextureButton;

ImportTextureButton.propTypes = {
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
};
