import React from 'react';
import ReactDOM from 'react-dom';
import Container from '@material-ui/core/Container';
import Login from './login.js';
import SimpleButton from "../components/buttons/simpleButton.js";
import HomeButton from "../components/buttons/homeButton.js";
import NewOrder from "./newOrder.js";
import {Animated} from "react-animated-css";
import Button from '@material-ui/core/Button';
import Arrow from "../assets/images/arrowIcon.svg";
import {withRouter} from "react-router-dom";
import "animate.css/animate.min.css";
import './screens.css';
import BgImage from "../assets/images/bg-acc.webp";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: 0,
      isLoading: true,
      screenWidth: 0,
      screenHeight: 0
    };
  }

  componentDidMount() {
    document.title = "WEMET - Création de carte";
    window.scrollTo(0, 0);
    window.addEventListener('resize', this._updateDimension);
    this._updateDimension();
    this.setState({isLoading: false});
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
  }

  _buttonClicked = (buttonId) => {
    if (buttonId === 'card') {
      this.setState({currentState: 1})
    } else if (buttonId === 'login') {
      this.props.history.push('/connexion');
    } else if (buttonId === 'company') {
      //this.setState({currentState: 2})
      this.props.history.push({pathname: '/nouvelle_commande', state: { orderType: 'company' }});
    } else if (buttonId === "person") {
      this.props.history.push({pathname: '/nouvelle_commande', state: { orderType: 'person' }});
    }
  }

  _handleState = (stateIndicator) => {
    if (stateIndicator === "-") {
      if (this.state.currentState > 0) {
        const tmp = this.state.currentState - 1;
        this.setState({currentState: tmp})
      }
    } else if (stateIndicator === "+") {
      if (this.state.currentState < 3) {
        const tmp = this.state.currentState + 1;
        this.setState({currentState: tmp})
      }
    }
  }

  render() {
    return (
      this.state.isLoading ? null :
        <div style={{
          height: '100vh',
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: "20% 22%",
          backgroundSize: "cover",
          display: 'flex'
        }}>
          <div
            style={{
              width: this.state.screenWidth < 768 ? '90%' : '42%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              background: 'white',
              borderRadius: 15,
              padding:"10px 20px 60px 20px"
            }}
          >
            <div>
              <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true} style={{height: "5em"}}>
                <h1 style={{display: "flex", justifyContent: "center", fontSize: "4em", color: "#003939"}}>WeCard</h1>
              </Animated>
              <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={1000}
                        animationOutDuration={1000} isVisible={true} style={{padding: 15, paddingBottom: 0}}>
                <h2 style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  fontSize: '1.3em',
                  margin: '40px 0',
                  color: "#003939"
                }}>Créez votre carte connectée en toute simplicité</h2>
              </Animated>
            </div>

            <h2 style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  fontSize: '1.1em',
                  color: "#003939",
                  margin: 0
                }}>Je commande :</h2>

            {/*Second button set*/}
            <div style={this.state.currentState === 0 ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: this.state.screenWidth < 768 ? 'column' : 'row',
              flexWrap: 'wrap',
            } : {display: "none"}}>
              <Animated animationIn="fadeInLeft" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Une WeCard" onClick={() => this._buttonClicked('person')}/>
              </Animated>
              <Animated animationIn="fadeInLeft" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Plusieurs WeCards" onClick={() => this._buttonClicked('company')}/>
              </Animated>
            </div>

            {/*Company button set*/}
            <div style={this.state.currentState === 2 ? {display: "flex", flexWrap: "wrap"} : {display: "none"}}>
              <Animated animationIn="fadeInDown" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
              </Animated>
              <Animated animationIn="fadeInLeft" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Charger un fichier CSV" onClick={() => this._buttonClicked('card')}/>
              </Animated>
              <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Création de cartes" onClick={() => this._buttonClicked('login')}/>
              </Animated>
              <Animated animationIn="fadeInRight" animationOut="zoomOutDown" animationInDuration={900}
                        animationOutDuration={900} isVisible={true}>
                <HomeButton buttonName="Importer mon design" onClick={() => this._buttonClicked('login')}/>
              </Animated>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(Home);
