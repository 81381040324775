import React from 'react';
import * as Services from '../services/apiServices.js';
import * as Security from '../services/securityServices.js';
import * as loader from '../assets/animations/loader.json';
import Container from '@material-ui/core/Container';
import Lottie from 'react-lottie';
import TextField from '@material-ui/core/TextField';
import SimpleButton from '../components/buttons/loginButtonNew.js';
import CheckboxRow from '../components/rows/checkboxRow.js';
import SnackBar from '../components/snackbars/snackbar.js';
import {withRouter} from "react-router-dom";
import RegisterComponent from "../components/register/register";

//option used for spinning loader
const lottieSpinnerOption = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      additionalAddress: '',
      phoneNumber: '',
      isUserCommercial: false,
      postalCode: '',
      termsAndConditionsValidated: true,
      isSnackbarVisible: false,
      snackbarMessage: '',
      snackbarSeverity: '',
      screenWidth: '',
      screenHeight: '',
      isLoaderVisible: false,
      promoCode: '',
      promoCodeValid: null
    };
  }

  _updateDimension = () => {
    this.setState({screenWidth: window.innerWidth});
    this.setState({screenHeight: window.innerHeight});
  }

  _redirectAfterSuccess = () => {
    this.props.history.push('/connexion');
  }

  render() {
    return (
      <div style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#07393D'
      }} className="c-formulaire_field_outlined">
        <RegisterComponent redirectAfterSuccess={this._redirectAfterSuccess}/>
      </div>
    );
  }
}

export default withRouter(Register);
