import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

 const LoginButton = (props) => {
  return (
    props.isColorPrimary
    ?
    <div onClick={() => props.onClick()} className="hoverButtonLogin pointer" style={{width: "100%"}}>
      <span className="hoverButtonLoginText">{props.buttonName}</span>
    </div>
    :
    <div onClick={() => props.onClick()} className="hoverButtonLogin pointer" style={{width: "100%"}}>
      <span className="hoverButtonLoginText">{props.buttonName}</span>
    </div>
  );
}
export default LoginButton;

LoginButton.propTypes = {
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
};
