import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MdTexture, MdColorLens } from 'react-icons/md';
import { BsCheckCircle } from 'react-icons/bs';
import { FaRegIdCard } from 'react-icons/fa';
import { IoLogoApple } from 'react-icons/io';
import { RiInformationLine } from 'react-icons/ri';
import { FiType } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { IoChatboxEllipsesSharp } from 'react-icons/io';
import { AiOutlineContacts, AiOutlineRotateRight } from 'react-icons/ai';
import { IoChatboxEllipses, IoChatboxSharp } from "react-icons/io5";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import FixedHelpBtn from "../fixedHelpBtn/FixedHelpBtn";
import LogoBlanc from '../../assets/images/logo_blanc.svg';


const getLogo = () => {
  return (
    <img src={LogoBlanc} style={{width: "2.2em"}}/>
  );
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: 'lightgrey',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'rgb(0, 55, 50)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  active: {
    backgroundColor: '#d2c3ff',
    cursor: 'pointer'
  },
  completed: {
    backgroundColor: '#003732',
    cursor: 'pointer'
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AiOutlineContacts />,
    2: <AiOutlineRotateRight />,
    3: <FaRegIdCard />,
    4: getLogo(),
    5: <MdTexture />,
    6: <MdColorLens />,
    7: <RiInformationLine />,
    8: <FiType />,
    9: <AiOutlineContacts />,
    10: <BsCheckCircle/>
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
    {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Fiche(s) de contact',
    'Orientation',
    'Type de carte',
    'Logo',
    'Texture',
    'Couleur',
    'Informations',
    'Validation'
  ];
}

const CustomizedSteppers = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={props.commandStep} connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel onClick={() => props.handleStepperClick(index)} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default CustomizedSteppers;
