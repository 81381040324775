import React, { useState, useEffect } from 'react';
import {withRouter, useHistory} from "react-router-dom";
import * as Services from '../services/apiServices.js';

function LoginFromWeb(props) {
    const locationSearch = props.location.search;
    const history = useHistory();

    useEffect(async () => {
        const params = new URLSearchParams(locationSearch);
        const token = params.get("token");

        localStorage.setItem("isUserConnected", "true");
        localStorage.setItem("token", token);

        const res = await Services.getMe();
        localStorage.setItem("account", JSON.stringify(res.account));
        history.push('/mes_commandes');
    }, [locationSearch]);

    return (
        <div className="login d-flex flex-column">
            <div className="row no-gutters flex-shrink-0">
                <div className="col-12 h-100">
                    <div className="d-flex align-items-center justify-content-center h-100 p-3 pb-5 mt-5">
                        Chargement...
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginFromWeb);
