import React from 'react';
import TextField from '@material-ui/core/TextField';
import CheckboxRow from '../rows/checkboxRow.js';
import SimpleButton from '../buttons/simpleButton.js';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import '../components.css';

const LargeProfileGrid = (props) => {
  const textFieldWidth = props.screenWidth * 0.20;
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <h1 className="marginAuto" style={{marginTop: 150, color: "#003939", marginBottom: 50} }>Mon profil</h1>
    {props.company && props.company !== '' ?
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField disabled variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Société' defaultValue={props.company} onChange={(e) => props.handleInputChange('company', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField disabled variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Code Société' value={props.companyCode}/>
        </span>
      </div> : null
    }
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Nom' defaultValue={props.lastName} onChange={(e) => props.handleInputChange('lastName', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Prenom' defaultValue={props.firstName} onChange={(e) => props.handleInputChange('firstName', e)}/>
        </span>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" disabled style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Email' defaultValue={props.email} onChange={(e) => props.handleInputChange('email', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Téléphone' defaultValue={props.phoneNumber} onChange={(e) => props.handleInputChange('phoneNumber', e)}/>
        </span>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Adresse de facturation' defaultValue={props.address} onChange={(e) => props.handleInputChange('address', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label="Complément d'adresse" defaultValue={props.additionalAddress} onChange={(e) => props.handleInputChange('additionalAddress', e)}/>
        </span>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
      <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} type='number' label="Code postal" defaultValue={props.postalCode} onChange={(e) => props.handleInputChange('postalCode', e)}/>
        </span>
        <span className='margin20' style={{marginTop: 0}}>
          <TextField variant="outlined" style={{width: textFieldWidth, minWidth: 500, maxWidth: 750}} label='Ville' defaultValue={props.city} onChange={(e) => props.handleInputChange('city', e)}/>
        </span>
      </div>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'row', width: "50%", marginTop: 10}}>
          <div onClick={props.saveUser} className="hoverButtonLogin pointer" style={{width: "50%"}}>
            <span className="hoverButtonLoginText">Valider</span>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', width: "50%", marginTop: 10}}>
          <div onClick={() => props.handleResetPasswordModalState()} className="hoverButtonLogin pointer" style={{width: "50%"}}>
            <span className="hoverButtonLoginText">Changer le mot de passe</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LargeProfileGrid;

LargeProfileGrid.propTypes = {
  company: PropTypes.string,
  companyCode: PropTypes.string,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  postalCode: PropTypes.string,
  address: PropTypes.string,
  additionAddress: PropTypes.string,
  changeUserCommercialState: PropTypes.func,
  isUserCommercial: PropTypes.bool,
  cardNumber: PropTypes.string,
  cardExpiration: PropTypes.string,
  cryptogram: PropTypes.string,
  isResetPasswordModalVidible: PropTypes.bool,
  handleResetPasswordModalState: PropTypes.func
};
